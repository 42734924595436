import styled from 'styled-components'

export const ContentExam = styled.div`
  width: 100%;
  border: 1px #ccc dashed;
  border-radius: 8px;
  padding: 15px;
  margin-top: 25px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  > img {
    height: 100px;
  }
`
