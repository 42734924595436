import { translate } from 'locales'

export const menuItems = [
  {
    id: 'chat',
    title: 'Chat com o paciente',
  },
  {
    id: 'anamnese',
    title: translate('menuItemsNew.anamnese'),
  },
  {
    id: 'exams',
    title: translate('menuItemsNew.exams'),
  },
  {
    id: 'diagnosticHypothesis',
    title: translate('menuItemsNew.diagnosticHypothesis'),
  },
  {
    id: 'conduct',
    title: translate('menuItemsNew.conduct'),
  },
  {
    id: 'images',
    title: translate('menuItemsNew.images'),
  },
  {
    id: 'historic',
    title: translate('menuItemsNew.historic'),
  },
  {
    id: 'accompaniments',
    title: translate('menuItemsNew.accompaniments'),
  },
  {
    id: 'examsAndAttests',
    title: translate('menuItemsNew.examsAndAttests'),
  },
  {
    id: 'prescription',
    title: translate('menuItemsNew.prescription'),
  },
]
