import React, { useContext, useState, createContext } from 'react'
import {
  DoctorSchenduleProps,
  SchenduleConfigProviderProps,
  SchenduleConfigStateProps,
} from './schenduleConfig.types'

const SchenduleConfigContext = createContext<SchenduleConfigStateProps>({
  doctorSchendule: [],
  setDoctorSchendule: () => {},
})

const SchenduleConfigProvider: React.FunctionComponent<SchenduleConfigProviderProps> = ({
  children,
}) => {
  const [doctorSchendule, setDoctorSchendule] = useState<
    Array<DoctorSchenduleProps>
  >([])

  const value: SchenduleConfigStateProps = {
    doctorSchendule,
    setDoctorSchendule,
  }

  return (
    <SchenduleConfigContext.Provider value={value}>
      {children}
    </SchenduleConfigContext.Provider>
  )
}

const useScheduleConfig = () => {
  return useContext(SchenduleConfigContext)
}

export { SchenduleConfigProvider, useScheduleConfig }
