import styled from 'styled-components'
import { Grid } from '@material-ui/core'

export const TopContent = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: 1px solid #f1f1f1;
`

export const GridContent = styled(Grid)`
  width: 100%;
  height: 100%;
  padding: 70px 0 0 0;
`

export const PatientsListToday = styled.div`
  span {
    font-size: 1.8rem !important;
    line-height: 2.3rem;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.black};
  }

  .list {
    width: 100%;
    height: initial;
    margin-top: 30px;
    position: relative;

    .list-card {
      width: 100%;
      height: 55px;
      background: ${({ theme }) => theme.colors.gray10};
      border-radius: ${({ theme }) => theme.radius.medium}px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 20px;
      margin-bottom: 8px;
      border: none;
      outline: none;

      :disabled {
        cursor: none;
      }

      span {
        font-size: 1.6rem !important;
        line-height: 2rem !important;
        color: ${({ theme }) => theme.black};
      }

      span.patient-item-time {
        color: ${({ theme }) => theme.colors.gray80};
        font-weight: normal !important;
      }
    }
  }
`

export const CalendarView = styled.div<{ height: number }>`
  width: 100%;
  height: ${({ height }) => height - 180}px;
  display: flex;
  flex-direction: column;

  span.schedule-title {
    font-size: 1.6rem !important;
    line-height: 2rem !important;
    color: ${({ theme }) => theme.colors.black};
  }

  @media (max-width: 480px) {
    height: ${({ height }) => height}px;
  }
`

export const DashboardList = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`

export const DashboardTop = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .area-month {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    button {
      background-color: transparent;
      border: none;
      outline: none;

      :disabled {
        cursor: no-drop;
      }
    }
  }

  .month {
    font-size: 1.4rem !important;
  }
`

export const DashboardCard = styled.div`
  width: 100%;
  min-height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${({ theme }) => theme.colors.gray10};
  border-radius: 8px;
  padding-left: 18px;
  padding-right: 18px;
  margin-bottom: 10px;

  .left {
    display: flex;
    flex-direction: column;
    font-size: 1.4rem;

    span {
      font-size: 1.4rem !important;
    }
  }

  .icon {
    width: 34px;
    height: 34px;
    border-radius: 50%;
    background: ${({ theme }) => theme.colors.secondaryLight};
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 18px !important;
      height: 18px !important;
    }
  }
`
