import React from 'react'
import moment from 'moment'

import { translate } from 'locales'

import { Title, Button, CardNutritionalGuidance, CardRecipe } from 'components'

// Styles
import * as S from './styles'

// Interfaces
import { IComponentProps } from './interfaces'

const NutritionalGuidance: React.FunctionComponent<IComponentProps> = ({
  doctorName,
  scheduleUUID,
  patientId,
  nutritional,
  setLoading,
  recipe,
}) => {
  return (
    <S.SectionNutri>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <span>{moment(new Date()).format('dddd, DD/MM/YYYY')}</span>
          <p>{`${translate('medicalRecord.by')} ${doctorName}`}</p>
        </div>
        <Button
          color="primary"
          type="button"
          size="normal"
          iconStyles={{ width: 18, marginRight: 15 }}
          styles={{ marginTop: 10 }}
          onClick={() => {
            window.open(
              'https://starbem-production.nyc3.digitaloceanspaces.com/generic/orientacao-nutricional/Orientac%CC%A7a%CC%83o%20nutricional%20-%20modelo%20.docx'
            )
          }}
        >
          {translate('medicalRecord.downloadModel')}
        </Button>
      </div>

      <Title text="Orientações nutricionais" />
      <div>
        <h3 style={{ marginTop: '25px', fontSize: '16px' }}>
          {translate('medicalRecord.sendOrientation')}
        </h3>
        <h5 style={{ marginTop: '25px', fontSize: '14px' }}>
          {translate('medicalRecord.obsPdf')}
        </h5>
      </div>
      <CardNutritionalGuidance
        patientId={patientId}
        scheduleUUID={scheduleUUID}
        nutritional={nutritional}
        setLoading={setLoading}
      />
      <div>
        <h3 style={{ marginTop: '25px', fontSize: '16px' }}>
          {translate('medicalRecord.sendRecipe')}
        </h3>
      </div>
      <CardRecipe
        patientId={patientId}
        scheduleUUID={scheduleUUID}
        recipe={recipe}
        setLoading={setLoading}
      />
    </S.SectionNutri>
  )
}

export default NutritionalGuidance
