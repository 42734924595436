import React, { useState } from 'react'
import './style.css'

import { translate } from 'locales'

import moment from 'moment'
import { Button, Input, Select, TextArea } from '../index'

interface IAccompaniment {
  state: string
  reason: string
  day: string
}

interface ModalShowProps {
  description?: string
  cancelModal?: boolean
  setDescription?: React.Dispatch<React.SetStateAction<string>>
  closeModal: () => void
  handleConfirm?: () => void
  accompaniment?: IAccompaniment
  setAccompaniment?: React.Dispatch<React.SetStateAction<IAccompaniment>>
}

const ModalShow: React.FunctionComponent<ModalShowProps> = ({
  description,
  cancelModal = true,
  setDescription,
  closeModal,
  handleConfirm,
  accompaniment,
  setAccompaniment,
}) => {
  const [nextModal, setNextModal] = useState<boolean>(cancelModal)
  const [other, setOther] = useState<boolean>(false)

  const verifyDay =
    !!accompaniment?.day &&
    moment(accompaniment?.day).format('YYYY-MM-DD') >=
      moment().format('YYYY-MM-DD')

  const verifyFinished = () => {
    if (!accompaniment) return false
    if (accompaniment?.state === 'não') return false
    return !accompaniment?.reason || !verifyDay
  }

  return (
    <>
      {nextModal ? (
        <>
          <div className="modal-container">
            <h2>{translate('modal.cancel')}</h2>
            <p>{translate('modal.confirmCancel')}</p>
            <div className="modal-input">
              <p>{translate('modal.noShow')}</p>
              <input
                type="radio"
                style={{ border: '1px solid #ffd2de' }}
                value="O paciente não entrou na videochamada"
                checked={
                  description === 'O paciente não entrou na videochamada'
                }
                onChange={(e) => {
                  setOther(false)
                  // @ts-ignore
                  setDescription(e.target.value)
                }}
              />
            </div>
            <div className="modal-input">
              <p>{translate('modal.conectionFail')}</p>
              <input
                type="radio"
                value="Falha na conexão da ligação"
                checked={description === 'Falha na conexão da ligação'}
                style={{ border: '1px solid #ffd2de' }}
                onChange={(e) => {
                  setOther(false)
                  // @ts-ignore
                  setDescription(e.target.value)
                }}
              />
            </div>
            {/* <div className="modal-input">
              <p>O Paciente recusou o chamado</p>
              <input
                type="radio"
                value="O Paciente recusou o chamado"
                style={{ border: '1px solid #ffd2de' }}
                checked={description === 'O Paciente recusou o chamado'}
                onChange={(e) => {
                  setOther(false)
                  // @ts-ignore
                  setDescription(e.target.value)
                }}
              />
            </div> */}
            <div className="modal-input">
              <p>{translate('modal.other')}</p>
              <input
                type="radio"
                value="Outro"
                style={{ border: '1px solid #ffd2de' }}
                checked={other}
                onChange={() => {
                  setOther(true)
                  // @ts-ignore
                  setDescription('')
                }}
              />
            </div>
            {other && (
              <input
                type="text"
                className="input-text"
                placeholder={translate('modal.describe')}
                // @ts-ignore
                onChange={(e) => setDescription(e.target.value)}
                value={description}
              />
            )}
            <div className="buttons-modal">
              <Button
                type="button"
                color="link-danger"
                onClick={closeModal}
                styles={{
                  marginTop: 10,
                  border: '1px solid red',
                  width: 200,
                  marginLeft: 5,
                }}
              >
                {translate('modal.close')}
              </Button>
              <Button
                type="button"
                color="primary"
                onClick={() => setNextModal(false)}
                disabled={!description}
                styles={{ marginTop: 10, width: 200, marginRight: 5 }}
              >
                {translate('modal.confirmCanceled')}
              </Button>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="modal-container-cancel">
            <h2>{translate('modal.finishAttendance')}</h2>
            {accompaniment && setAccompaniment && (
              <>
                <div className="modal-select">
                  <p>{translate('modal.finishAccompaniment')}</p>
                  <Select
                    name="confirmated"
                    list={['sim', 'não']}
                    defaultValue={accompaniment?.state}
                    value={accompaniment?.state}
                    onChange={(e: any) => {
                      setAccompaniment({
                        ...accompaniment,
                        state: e.target.value,
                      })
                    }}
                  />
                </div>
                {accompaniment?.state === 'sim' && (
                  <div className="modal-inputs">
                    <label htmlFor="reason">
                      {`${translate('modal.reasonAccompaniment')}:`}
                    </label>
                    <TextArea
                      name="reason"
                      maxlength={250}
                      value={accompaniment.reason}
                      onChange={(e: any) => {
                        setAccompaniment({
                          ...accompaniment,
                          reason: e.target.value,
                        })
                      }}
                    />
                    <label htmlFor="day">
                      {`${translate('modal.dayAccompaniment')}:`}
                    </label>
                    <div className="inputs-times">
                      <div style={{ width: '45%' }}>
                        <Input
                          name="day"
                          type="date"
                          min={new Date().toISOString().split('T')[0]}
                          value={accompaniment.day}
                          onChange={(e: any) => {
                            setAccompaniment({
                              ...accompaniment,
                              day: e.target.value,
                            })
                          }}
                        />
                      </div>
                    </div>
                    {moment(accompaniment?.day).isValid() && !verifyDay && (
                      <span style={{ color: 'red' }}>
                        {translate('modal.dayFuture')}
                      </span>
                    )}
                  </div>
                )}
              </>
            )}
            <p className="modal-p">{translate('modal.nextConfirm')}</p>
            <div className="buttons-modal">
              <Button
                type="button"
                color="link-danger"
                onClick={() => closeModal()}
                styles={{
                  marginTop: 10,
                  border: '1px solid red',
                  width: 200,
                  marginLeft: 5,
                }}
              >
                {translate('modal.close')}
              </Button>
              <Button
                type="button"
                color="primary"
                // @ts-ignore
                onClick={() => handleConfirm()}
                disabled={verifyFinished()}
                styles={{ marginTop: 10, width: 200, marginRight: 5 }}
              >
                {translate('modal.finish')}
              </Button>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default ModalShow
