import React from 'react'
import './styles.scss'

interface TitleProps {
  text: string
}

const defaultProps = {
  text: '',
}

const Title: React.FunctionComponent<TitleProps> = ({ text }) => {
  return (
    <div className="title-content">
      <span>{text}</span>
      <div className="title-content__border" />
    </div>
  )
}

Title.defaultProps = defaultProps

export default Title
