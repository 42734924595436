import React from 'react'
import './styles.scss'

interface CardProps {
  children?: any
  size?: 'full' | 'auto'
  bg?: 'primary' | 'secondary' | 'gray' | 'default'
  margin?: 'horizontal' | 'vertical' | 'none'
  styles?: any
  className?: string
}

const defaultProps: CardProps = {
  children: <></>,
  size: 'auto',
  bg: 'default',
  margin: 'none',
  styles: {},
  className: '',
}

const Card: React.FunctionComponent<CardProps> = ({
  children,
  size,
  margin,
  styles,
  className,
}) => {
  return (
    <div className={`card ${size} ${margin} ${className}`} style={styles}>
      {children}
    </div>
  )
}

Card.defaultProps = defaultProps

export default Card
