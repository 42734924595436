import React from 'react'

import * as S from './styles'

interface Props {
  isDoctor?: boolean
  name?: string
  message: string
  time: string
}

const Message: React.FC<Props> = ({ isDoctor, name, message, time }) => {
  return (
    <S.MessageContainer>
      <S.MessageName isDoctor={isDoctor}>{name}</S.MessageName>
      <S.MessageContent isDoctor={isDoctor}>
        <S.MessageText>{message}</S.MessageText>
      </S.MessageContent>
      <S.MessageHour isDoctor={isDoctor}>{time}</S.MessageHour>
    </S.MessageContainer>
  )
}

export default Message
