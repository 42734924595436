import styled from 'styled-components'
import { Card } from 'components'

export const ContentCard = styled(Card)`
  flex-wrap: nowrap;
  @media screen and (max-width: 600px) {
    flex-wrap: wrap;
  }
`
export const Content = styled.div`
  width: 100%;
  max-width: 1200px;
  position: relative;
  padding: 90px 16px 16px;
  min-height: 80vh;

  margin: 30px auto;

  h1.title-config {
    font-size: 2.4rem;
    line-height: 3rem;
    color: ${({ theme }) => theme.colors.black};
  }
`

export const ContentCharts = styled.div`
  max-width: 450px;
  display: flex;
  align-items: center;
  flex-direction: column;

  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .buttons {
      margin: 20px 0;
      display: flex;
      align-items: center;

      justify-self: end;
    }
  }
`
export const ContentEmotions = styled.div`
  margin-left: 40px;
  flex-wrap: wrap;
  padding: 15x;
`

export const CardEmotions = styled.div`
  padding: 5px 10px;
  height: 30px;
  margin: 10px;
  display: inline-block;

  color: ${(props) => props.color};

  border-color: ${(props) => props.color};
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
`

export const EmotionNumber = styled.span`
  color: white;
  background-color: ${(props) => props.color};
  width: 20px;
  height: 20px;
  border-radius: 10px;

  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: 700;
`

export const EmotionName = styled.span`
  margin-left: 10px;
  font-weight: 700;
`

export const ContentComents = styled.div`
  align-items: flex-start;
  justify-content: start;
`

export const CardComent = styled.div`
  width: 250px;
  height: 150px;
  overflow-y: scroll;

  padding: 5px 10px;
  margin: 10px;
  display: inline-block;
`
export const ComentName = styled.li`
  font-weight: 500;
  align-items: center;
`
