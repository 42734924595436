import styled from 'styled-components'

export const Wrapper = styled.div`
  background-color: var(--color-error);
  color: #fff;
  padding: 1rem;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  max-width: 350px;
`
