import styled from 'styled-components'

interface Props {
  isDoctor?: boolean
}

export const ChatContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
`

export const ChatContent = styled.div`
  height: 500px;
  overflow-y: scroll;
  scroll-behavior: smooth;
  background-color: ${({ theme }) => theme.colors.gray10};
  border-radius: 8px;
  padding: 10px;
`

export const ChatBottom = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
`

export const ChatInput = styled.textarea`
  display: flex;
  flex: 1;
  min-height: 50px;
  max-height: 100px;
  border: none;
  background: ${({ theme }) => theme.colors.gray10};
  padding: 10px;
  border-radius: 8px;
  margin-right: 10px;
  resize: none;
`

export const ChatButton = styled.button`
  width: 50px;
  height: 50px;
  border-radius: 8px;
  border: none;
  background: ${({ theme }) => theme.colors.secondary};
  color: ${({ theme }) => theme.colors.white};

  :disabled {
    opacity: 0.5;
    cursor: no-drop;
  }
`

// Message
export const MessageContainer = styled.div<Props>`
  display: flex;
  flex-direction: column;
  align-items: ${({ isDoctor }) => (isDoctor ? 'flex-end' : 'flex-start')};
  margin-bottom: 10px;
`

export const MessageName = styled.div<Props>`
  font-size: 1.2rem;
  font-weight: normal;
  color: ${({ theme }) => theme.colors.gray30};
  align-self: ${({ isDoctor }) => (isDoctor ? 'flex-end' : 'flex-start')};
  margin-bottom: 5px;
`

export const MessageContent = styled.div<Props>`
  display: flex;
  padding: 10px;
  max-width: 60%;
  background: ${({ theme, isDoctor }) =>
    isDoctor ? theme.colors.primary : theme.colors.secondaryLight};
  border-radius: ${({ isDoctor }) =>
    isDoctor ? '10px 10px 0px 10px' : '0px 10px 10px 10px'};
  align-self: ${({ isDoctor }) => (isDoctor ? 'flex-end' : 'flex-start')};
`

export const MessageHour = styled.div<Props>`
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.gray20};
  align-self: ${({ isDoctor }) => (isDoctor ? 'flex-end' : 'flex-start')};
  margin-top: 5px;
`

export const MessageText = styled.div`
  font-size: 1.2rem;
  font-weight: normal;
`
