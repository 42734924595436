import React from 'react'
import { translate } from 'locales'
// Styles
import { Title } from 'components'
import useStorage from 'hooks/storageSync'
import * as S from './styles'

// Interfaces
import { IComponentProps } from './interfaces'

// Hooks

const Resume: React.FunctionComponent<IComponentProps> = ({
  doctorName,
  scheduleUUID,
  scheduleData,
}) => {
  const [storage]: any = useStorage(scheduleUUID, 'complementarExams')

  // Recupera os valores salvos no storage para a consulta atual na sessão Conduct
  const dataStorage: any = storage
  const analysis = dataStorage?.analysis
  const medicalAllergy =
    scheduleData?.patient.medical_record.medical_allergy_description
  const diabetic = scheduleData?.patient.medical_record.diabetic
  const pathologicalAntecedents =
    scheduleData?.patient.medical_record.Medical_record_heath_problem

  const continuousRemedy =
    scheduleData?.patient.medical_record.continuous_remedy

  return (
    <S.SectionContent>
      <span>{translate('medicalRecord.resume')}</span>
      <p>{`${translate('medicalRecord.by')} ${doctorName}`}</p>

      <S.Div>
        <span>{translate('medicalRecord.reason')}</span>
        <h5 style={{ marginTop: '10px', fontSize: '14px' }}>
          {!scheduleData?.symptoms
            ? translate('medicalRecord.uninformed')
            : scheduleData?.symptoms}
        </h5>
      </S.Div>
      <S.Div className="subsection-item">
        <span>{translate('medicalRecord.allergies')}</span>
        <p>
          {!medicalAllergy
            ? translate('medicalRecord.uninformed')
            : medicalAllergy}
        </p>
      </S.Div>
      <S.Div className="subsection-item">
        <span>{translate('medicalRecord.diabetes')}</span>
        <p>
          {diabetic === true
            ? translate('general.yes')
            : translate('general.no')}
        </p>
      </S.Div>
      <S.Div className="subsection-item">
        <span>{translate('medicalRecord.pathologicalBackground')}</span>

        {pathologicalAntecedents?.map((item: any) => {
          return (
            <p key={item.name}>
              {!item.name ? translate('medicalRecord.uninformed') : item.name}
            </p>
          )
        })}
      </S.Div>
      <S.Div className="subsection-item">
        <span>{translate('medicalRecord.continuousRemedy')}</span>
        <p>
          {!continuousRemedy
            ? translate('medicalRecord.uninformed')
            : continuousRemedy}
        </p>
      </S.Div>
      <S.Div className="subsection">
        <Title text={translate('medicalRecord.analysis')} />
        <div className="subsection-item">
          <p>{analysis?.analysis}</p>
        </div>
      </S.Div>
    </S.SectionContent>
  )
}

export default Resume
