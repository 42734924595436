import api from 'configs/api'
import moment from 'moment'

const dateFormated = (value: Date) => {
  const dayInit = moment(value).format('01/MM/YYYY')
  const lastDay = moment.utc(value).daysInMonth()
  const dayEnd = moment(value).format(`${lastDay}/MM/YYYY`)
  return `start=${dayInit}&end=${dayEnd}`
}

export const getCsat = async (
  id: string,
  value: Date,
  setCountCsat: (value: number) => void,
  setRatings: (value: any) => void,
  setComments: (value: string[]) => void,
  setLoading: (value: boolean) => void
) => {
  setLoading(true)
  let formattedDate = ''
  try {
    formattedDate = dateFormated(value)
    const { data } = await api.get(
      `rating/ms/v1/csat/doctor/${id}?${formattedDate}`
    )

    const countRotate = Number(data?.csat) || 0
    setCountCsat(countRotate)

    setRatings(data?.emotions || [])
    setComments(Array.isArray(data?.comments) ? data.comments : [])
  } catch (e) {
    console.error(
      `Erro ao buscar CSAT para o ID: ${id} no período ${formattedDate}:`,
      e
    )
  } finally {
    setLoading(false)
  }
}
