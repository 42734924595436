/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react'

import { translate } from 'locales'
import { Button } from 'components'

import * as S from './styles'

interface IComponentProps {
  record: any
}

const CardAnamneseNutri: React.FunctionComponent<IComponentProps> = ({
  record,
}) => {
  const notInformad = translate('medicalRecord.uninformed')

  return (
    <S.SectionContent style={{ marginTop: '10px' }}>
      <div className="subsection-item">
        <h3>{translate('medicalRecord.reason')}:</h3>
        {record?.reason?.map((item: string) => <p key={item}>{item}</p>) ||
          notInformad}
      </div>
      <div className="subsection-item" style={{ marginTop: '10px' }}>
        <h4>{translate('nutritionalReason.comments')}:</h4>
        <p>{record?.reason_comments || notInformad}</p>
      </div>
      <div className="subsection-item" style={{ marginTop: '10px' }}>
        <h3>{translate('medicalRecord.pathologies')}</h3>
        {record?.pathologies?.map((item: string) => <p key={item}>{item}</p>) ||
          notInformad}
      </div>
      <div className="subsection-item" style={{ marginTop: '10px' }}>
        <h4>{translate('medicalRecord.others')}:</h4>
        <p>{record?.pathologies_comments || notInformad}</p>
      </div>
      <div className="subsection-item" style={{ marginTop: '10px' }}>
        <h3>{translate('nutritionalReason.medicines')}</h3>
        <p>{record?.medicines === true ? 'Sim' : 'Não'}</p>
      </div>
      <div className="subsection-item" style={{ marginTop: '10px' }}>
        <h4>{translate('nutritionalReason.which')}:</h4>
        <p>{record?.medicines_comments || notInformad}</p>
      </div>
      <div className="subsection-item" style={{ marginTop: '10px' }}>
        <h3>{translate('nutritionalReason.supplements')}:</h3>
        <div style={{ display: 'flex', gap: '10px' }}>
          {record?.supplements?.map((tag: string) => (
            <Button
              key={tag}
              color="tag-active"
              type="button"
              className="tag-button"
            >
              {tag}
            </Button>
          )) || notInformad}
        </div>
        <div className="subsection-item" style={{ marginTop: '10px' }}>
          <h4>{translate('nutritionalReason.comments')}:</h4>
          <p>{record?.supplements_comments || notInformad}</p>
        </div>
        <div className="subsection-item" style={{ marginTop: '10px' }}>
          <h3>{translate('nutritionalReason.praticePhysicalActivity')}:</h3>
          <p>{record?.practice_activity === true ? 'Sim' : 'Não'}</p>
        </div>
        <div className="subsection-item" style={{ marginTop: '10px' }}>
          <h4>{translate('nutritionalReason.frequencyAndSchedule')}</h4>
          <p>{record?.practice_activity_comments || notInformad}</p>
        </div>
        <div className="subsection-item" style={{ marginTop: '10px' }}>
          <h3>{translate('nutritionalReason.waterConsumption')}:</h3>
          <p>{record?.water_consumption}</p>
        </div>
        <div className="subsection-item" style={{ marginTop: '10px' }}>
          <h4>{translate('nutritionalReason.comments')}:</h4>
          <p>{record?.water_consumption_comments || notInformad}</p>
        </div>
        <div className="subsection-item" style={{ marginTop: '10px' }}>
          <h3>{translate('nutritionalReason.intestinalHabit')}</h3>
          <div style={{ display: 'flex', gap: '5px', marginTop: '5px' }}>
            <h4>{translate('nutritionalReason.frequency')}:</h4>
            <p>{record?.frequency_intestinal_habit || notInformad}</p>
          </div>
          <div style={{ display: 'flex', gap: '5px' }}>
            <h4>{translate('nutritionalReason.consistency')}</h4>
            <p>{record?.consistency_intestinal_habit || notInformad}</p>
          </div>
        </div>
        <div className="subsection-item" style={{ marginTop: '10px' }}>
          <h4>{translate('nutritionalReason.comments')}:</h4>
          <p>{record?.intestinal_habit_comments || notInformad}</p>
        </div>
        <div className="subsection-item" style={{ marginTop: '10px' }}>
          <h3>{translate('nutritionalReason.sleep')}</h3>
          {record?.sleep || notInformad}
        </div>
        <div className="subsection-item" style={{ marginTop: '10px' }}>
          <h4>{translate('nutritionalReason.comments')}:</h4>
          {record?.sleep_comments}
        </div>
        <div className="subsection-item" style={{ marginTop: '10px' }}>
          <h3>{translate('nutritionalReason.foodAllergiesOrIntolerance')}</h3>
          <p>{record?.intolerances}</p>
        </div>
        <div className="subsection-item" style={{ marginTop: '10px' }}>
          <h4>{translate('nutritionalReason.which')}:</h4>
          <p>{record?.intolerances_comments || notInformad}</p>
        </div>
        <div className="subsection-item" style={{ marginTop: '10px' }}>
          <h3>{translate('nutritionalReason.youAre')}</h3>
          <p>{record?.diet || notInformad}</p>
        </div>
        <div className="subsection-item" style={{ marginTop: '10px' }}>
          <h4>{translate('nutritionalReason.comments')}:</h4>
          <p>{record?.diet_comments}</p>
        </div>
        <div className="subsection-item" style={{ marginTop: '10px' }}>
          <h3>{translate('nutritionalReason.foodRoutine')}</h3>
          <div style={{ display: 'flex', gap: '5px', marginTop: '5px' }}>
            <h4>{translate('nutritionalReason.breakfast')}:</h4>
            <p>{record?.routine_breakfast || notInformad}</p>
          </div>
          <div style={{ display: 'flex', gap: '5px' }}>
            <h4>{translate('nutritionalReason.morningSnack')}:</h4>
            <p>{record?.routine_morning_snack || notInformad}</p>
          </div>
          <div style={{ display: 'flex', gap: '5px' }}>
            <h4>{translate('nutritionalReason.lunch')}:</h4>
            <p>{record?.routine_lunch || notInformad}</p>
          </div>

          <div style={{ display: 'flex', gap: '5px' }}>
            <h4>{translate('nutritionalReason.afternoonSnack')}:</h4>
            <p>{record?.routine_afternoon_snack || notInformad}</p>
          </div>

          <div style={{ display: 'flex', gap: '5px' }}>
            <h4>{translate('nutritionalReason.dinner')}:</h4>
            <p>{record?.routine_dinner || notInformad}</p>
          </div>
          <div style={{ display: 'flex', gap: '5px' }}>
            <h4>{translate('nutritionalReason.supper')}:</h4>
            <p>{record?.routine_supper || notInformad}</p>
          </div>
          <div style={{ display: 'flex', gap: '5px' }}>
            <h4>{translate('nutritionalReason.weekends')}:</h4>
            <p>{record?.routine_weekends || notInformad}</p>
          </div>
        </div>
        <div className="subsection-item" style={{ marginTop: '10px' }}>
          <h4>{translate('nutritionalReason.anyFoodAversions')}</h4>
          <p>{record?.food_aversion}</p>
        </div>
        <div className="subsection-item" style={{ marginTop: '10px' }}>
          <h4>{translate('nutritionalReason.anyConsiderations')}</h4>
          <p>{record?.considerations}</p>
        </div>
      </div>
    </S.SectionContent>
  )
}
export default CardAnamneseNutri
