import React from 'react'
import moment from 'moment'

import { translate } from 'locales'

import { Title } from 'components'
import CardPsychologicalGuidance from 'components/CardPsychologicalGuidance'

// Styles
import * as S from './styles'

// Interfaces
import { IComponentProps } from './interfaces'

const PsychologicalGuidance: React.FunctionComponent<IComponentProps> = ({
  doctorName,
  scheduleUUID,
  patientId,
  attachment,
  setLoading,
}) => {
  return (
    <S.SectionNutri>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <span>{moment(new Date()).format('dddd, DD/MM/YYYY')}</span>
          <p>{`${translate('medicalRecord.by')} ${doctorName}`}</p>
        </div>
      </div>

      <Title text="Orientações psicólogicas" />
      <div>
        <h3 style={{ marginTop: '25px', fontSize: '16px' }}>
          {translate('medicalRecord.sendOrientationPsychological')}
        </h3>
        <h5 style={{ marginTop: '25px', fontSize: '14px' }}>
          {translate('medicalRecord.obsPdf')}
        </h5>
      </div>
      <CardPsychologicalGuidance
        patientId={patientId}
        scheduleUUID={scheduleUUID}
        attachment={attachment}
        setLoading={setLoading}
      />
    </S.SectionNutri>
  )
}

export default PsychologicalGuidance
