import React from 'react'
import { translate } from 'locales'

// Styles
import { Title } from 'components'
import * as S from './styles'

// Interfaces
import { IComponentProps } from './interfaces'

const TimeLineFollowUp: React.FunctionComponent<IComponentProps> = ({
  record,
}) => {
  const notInformad = translate('medicalRecord.uninformed')

  return (
    <S.SectionContent style={{ padding: 10 }}>
      <div className="subsection">
        <Title text={translate('medicalRecord.accompanimentWithNurse')} />
        <span>{translate('medicalRecord.accompanimentReason')}</span>
        <div className="subsection-item">
          <p style={{ margin: '10px 0' }}>
            - {record.reason_accompaniment || notInformad}
          </p>
        </div>

        <span>{`${translate('medicalRecord.anotations')}:`}</span>
        <div className="subsection-item">
          {record?.annotation?.map((i: any) => (
            <p style={{ margin: '10px 0' }} key={i.id}>
              - {i.annotation || notInformad}
            </p>
          ))}
        </div>
      </div>
    </S.SectionContent>
  )
}

export default TimeLineFollowUp
