const prescriptionData = {
  CorPrimaria: '#ff3f72',
  CorSecundaria: '',
  LogoURL:
    'https://starbem-site-cms.s3.amazonaws.com/images/Starbem_logo_b001241faf.png',
  Medico: {
    Especialidades: ['Clínica Geral'],
    RegistroProfissional: {
      Numero: '',
      Conselho: 'CRM',
      UF: '',
    },
    Nome: '',
    Documento: '',
    Endereco: {
      Endereco1: 'Rua Serra de Japi, 786',
      Endereco2: '',
      Cidade: 'São Paulo',
      Bairro: 'Vila Gomes Jardim',
      Estado: 'SP',
      CodigoPostal: '03309000',
    },
    Sexo: '',
    Email: '',
  },
  Paciente: {
    Nome: '',
    Nascimento: '',
    Sexo: '',
    TelefoneCelular: '',
    Email: '',
    Altura: '',
    Peso: '',
    Endereco: {
      Endereco1: '',
      Endereco2: '',
      Cidade: '',
      Bairro: '',
      Estado: '',
      CodigoPostal: '',
    },
    Documento: '',
    Alergias: [],
    ReferenciaExterna: '',
    Responsaveis: [],
  },
  RegistroProntuarioEletronico: {
    ReferenciaExterna: '',
    TipoConsulta: 'Teleconsulta',
  },
  Estabelecimento: {
    Nome: 'StarBem Desenvolvimento de Software LTDA',
    CNES: '',
    Endereco: {
      Endereco1: 'Rua Serra de Japi, 786',
      Endereco2: 'APT 83',
      Bairro: 'Vila Gomes Jardim',
      Cidade: 'São Paulo',
      Estado: 'SP',
      CodigoPostal: '03309000',
    },
    Contato: {
      TelefoneComercial: '11991987278',
      Celular: '11991987278',
      Email: 'faleconosco@starbem.app',
      Site: 'www.starbem.app',
    },
  },
  CertificadoDigitalObrigatorio:
    process.env.REACT_APP_NEXODATA_REQUIRED_CERTIFIED !== 'false',
}

export default prescriptionData
