import React from 'react'

// Styles
import * as S from './styles'

interface ICardDate {
  day: string
  month: string
  year: string
}

const CardDate: React.FunctionComponent<ICardDate> = ({ day, month, year }) => {
  return (
    <S.Container>
      <div className="top">
        <span className="text text-day">{day}</span>
        <span className="text">{month}</span>
      </div>
      <div className="divisor" />
      <div className="bottom">
        <span className="text">{year}</span>
      </div>
    </S.Container>
  )
}

export default CardDate
