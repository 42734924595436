import moment from 'moment'

export const getTimes = (start: any, interval: number): string[] => {
  const rangeTime: string[] = []
  const time = '24:00:00'
  const getDate = moment(start).format('YYYY-MM-DD')
  const finalMount = moment.utc(`${getDate}T${time}Z`)

  let initialTime = moment.utc(start).add(interval, 'minutes')
  const finalTime = moment.utc(finalMount)

  while (initialTime <= finalTime) {
    rangeTime.push(moment(initialTime).format('HH:mm'))
    initialTime = moment(initialTime).add(interval, 'minutes')
  }

  return rangeTime
}

interface CountryNames {
  [key: string]: string
}
export const countryNames: CountryNames = {
  BR: 'Brasil',
  IE: 'Irlanda',
  US: 'Estados Unidos',
  UK: 'Reino Unido',
  FR: 'França',
  DE: 'Alemanha',
  IT: 'Itália',
  ES: 'Espanha',
  JP: 'Japão',
  CN: 'China',
  RU: 'Rússia',
  CA: 'Canadá',
  AU: 'Austrália',
  IN: 'Índia',
  MX: 'México',
  KR: 'Coreia do Sul',
  SA: 'Arábia Saudita',
  AR: 'Argentina',
  ID: 'Indonésia',
  ZA: 'África do Sul',
  NG: 'Nigéria',
  EG: 'Egito',
  TH: 'Tailândia',
  NL: 'Países Baixos',
  BE: 'Bélgica',
  CH: 'Suíça',
  PT: 'Portugal',
  AT: 'Áustria',
  SE: 'Suécia',
  TR: 'Turquia',
  PL: 'Polônia',
  BO: 'Bolívia',
  CL: 'Chile',
  CO: 'Colômbia',
  EC: 'Equador',
  GY: 'Guiana',
  PY: 'Paraguai',
  PE: 'Peru',
  SR: 'Suriname',
  UY: 'Uruguai',
  VE: 'Venezuela',
}
