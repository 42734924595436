import React from 'react'
import { useDropzone } from 'react-dropzone'
import EditIcon from '@material-ui/icons/Edit'
import api from 'configs/api'

// Context
import { useUser } from 'context/user/user.context'

import { translate } from 'locales/index'

// Styles
import defaultImg from 'assets/images/avatar.jpg'
import { alertDanger, alertSuccess } from 'utils/toast'
import * as S from './styles'

// Interfaces
import { IPhoto } from './interfaces'

const Photo: React.FunctionComponent<IPhoto> = ({ setLoading }) => {
  const { userData, setUserData } = useUser()

  /* eslint-disable */
  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    onDrop: async (acceptedFiles) => {
      const maxSize = 4 * 2048 * 2048
      const acceptFiles = [
        'image/jpeg',
        'image/pjpeg',
        'image/png',
        'image/gif',
      ]

      if (acceptedFiles[0].size > maxSize) {
        return alertDanger(translate('profile.fileSize'))
      }

      if (!acceptFiles.includes(acceptedFiles[0].type)) {
        return alertDanger(translate('profile.invalidFile'))
      }

      setLoading(true)
      const doctorId = userData?.id
      const data = new FormData()
      data.append('file', acceptedFiles[0])

      try {
        const save = await api.post(
          `/doctors/v1/user/${doctorId}/upload`,
          data,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        )

        setUserData({
          ...userData,
          profile: { ...userData.profile, photo: save.data.Profile.photo },
        })
        setLoading(false)
        alertSuccess(translate('profile.updateFileSuccess'))
      } catch (err) {
        setLoading(false)
        alertDanger(translate('profile.updateFileError'))
      }
    },
  })
  /* eslint-enable */

  return (
    <S.PhotoProfile>
      <h3>{translate('profile.photo')}</h3>
      <div className="content-photo">
        <div {...getRootProps()} className="photo-content-wrapper">
          <img src={userData.profile.photo || defaultImg} alt={userData.name} />
          <input {...getInputProps()} />
          <div className="edit-icon">
            <EditIcon />
          </div>
          <div className="photo-label">{translate('profile.updatePhoto')}</div>
        </div>
      </div>
    </S.PhotoProfile>
  )
}

export default Photo
