import React, { useState, useContext, createContext } from 'react'

// Types
import {
  SchenduleProps,
  SchenduleProviderProps,
  SchenduleStateProps,
} from './schendule.types'

const defaultSchendule = {}

const SchenduleContext = createContext<SchenduleStateProps>({
  schenduleData: defaultSchendule,
  scheduleSelect: {},
  setSchenduleData: () => {},
  setScheduleInStorage: () => {},
  getScheduleInStorage: () => {},
})

const SchenduleProvider: React.FunctionComponent<SchenduleProviderProps> = ({
  children,
}) => {
  const [schenduleData, setSchenduleData] = useState<SchenduleProps>(
    defaultSchendule
  )
  const [scheduleSelect, setScheculeSelect] = useState<SchenduleProps>({})

  const setScheduleInStorage = (scheduleData: SchenduleProps) => {
    setScheculeSelect(scheduleData)
    window.localStorage.setItem(
      '@StarBem:scheduleSelect',
      JSON.stringify(scheduleData)
    )
  }

  const getScheduleInStorage = () => {
    const scheduling: any = window.localStorage.getItem(
      '@StarBem:scheduleSelect'
    )
    setScheculeSelect(JSON.parse(scheduling))
  }

  const value: SchenduleStateProps = {
    schenduleData,
    scheduleSelect,
    setSchenduleData,
    setScheduleInStorage,
    getScheduleInStorage,
  }

  return (
    <SchenduleContext.Provider value={value}>
      {children}
    </SchenduleContext.Provider>
  )
}

const useSchendule = () => {
  return useContext(SchenduleContext)
}

export { SchenduleProvider, useSchendule }
