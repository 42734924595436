import React from 'react'
import moment from 'moment'

import { translate } from 'locales'

import * as S from './styles'

const Footer = () => {
  return (
    <S.Container>
      <div className="legal">
        {`© Copyright 2020-${moment().format('YYYY')} Starbem - ${translate(
          'general.allRightsReserved'
        )} `}
        <strong>| Versão: 2.0.1</strong>
      </div>
    </S.Container>
  )
}

export default Footer
