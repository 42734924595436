import { MEDIA_SIZES } from 'utils/variables'

type Labels = keyof typeof MEDIA_SIZES

export const isBrowser = typeof window !== 'undefined'
export const isSafari = () =>
  /^((?!chrome|android).)*safari/i.test(navigator.userAgent)

export const media = (Object.keys(MEDIA_SIZES) as Labels[]).reduce(
  (acc, label) => {
    acc[label] = `
      @media (min-width: ${MEDIA_SIZES[label] / 16}em)`
    return acc
  },
  {} as Record<Labels, string>
)

export const mediaMax = (Object.keys(MEDIA_SIZES) as Labels[]).reduce(
  (acc, label) => {
    acc[label] = `
      @media (max-width: ${(MEDIA_SIZES[label] - 1) / 16}em)`
    return acc
  },
  {} as Record<Labels, string>
)

export function createFirstAndLastName(name: string): string {
  if (!name) return ''

  const splitName = name.trim().split(/\s+/)

  if (splitName.length > 1) {
    return `${splitName[0]} ${splitName[1]}`
  } else {
    return splitName[0]
  }
}

export function normalizeName(name: string): string {
  return name
    .trim()
    .toLowerCase()
    .split(/\s+/)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}
