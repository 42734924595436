import * as yup from 'yup'
import { regexPassword } from 'utils/validations'

export const schemaLogin = yup.object().shape({
  email: yup
    .string()
    .required('Informe o seu email!')
    .email('Informe um email valido!'),
  password: yup
    .string()
    .required('Informe sua senha!')
    .min(8, 'A senha deve conter no minimo 8 caracteres!')
    .test(
      'test',
      'A senha deve conter letras maiusculas e minusculas, numeros e caracteres especiais!',
      (value) => {
        if (!value) return false
        return regexPassword.test(value)
      }
    ),
})
