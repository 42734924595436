import styled from 'styled-components'
import { Popover } from 'react-bootstrap'

export const ContentConfig = styled.div<{ height: number }>`
  width: 100%;
  max-width: 1400px;
  padding: 40px 0 0 0;

  h1.title-config {
    font-size: 1.8rem;
    line-height: 2.2rem;
    color: ${({ theme }) => theme.colors.black};
  }

  .container-config {
    width: 100%;
    min-height: 200px;
    height: ${({ height }) => height - 180}px;
    border-top: 1px solid ${({ theme }) => theme.colors.gray10};
    border-radius: ${({ theme }) => theme.radius.medium}px;
    margin-top: 10px;

    .calendar-view {
      height: 100%;

      .fc-timegrid-event .fc-event-main {
        padding: 0;
      }

      .fc-v-event {
        border-color: ${({ theme }) => theme.colors.secondary};
        background-color: ${({ theme }) => theme.colors.secondaryLight};
        padding: 0;
      }

      .eventContent {
        width: 100%;
        height: 100%;
        padding: 1rem;

        p {
          margin: 0;
          color: ${({ theme }) => theme.colors.secondary};
          text-align: center;
        }
      }
    }
  }

  @media screen and (min-width: 961px) {
    margin: 30px auto;
  }
`

export const PopverContainer = styled(Popover)`
  width: 264px;
  @media screen and (max-width: 600px) {
    position: fixed !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    height: 550px !important;
  }

  h3 {
    font-size: 1.2rem;
  }

  .popover {
    &-bordy,
    &-header {
      padding: 12px;
    }

    &-body {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      padding: 15px;

      h4 {
        font-size: 1.4rem;
      }

      button {
        margin-top: 24px;
      }

      select,
      input {
        width: 100% !important;
        height: 40px !important;
        margin-top: 10px;
        margin-bottom: 10px;
        font-size: 1.4rem;
        line-height: initial;
        padding-left: 10px;
        padding-right: 10px;
        border-radius: 4px;
        border: 1px solid ${({ theme }) => theme.colors.gray20};

        &[type='checkbox'] {
          width: initial !important;
        }
      }

      .form-checkbox {
        .group-checkbox {
          display: flex;
          align-items: center;

          input {
            margin-right: 8px;
          }
        }
      }
    }
  }

  .buttonHighlight {
    width: 100%;
  }

  .button-cancel-schedule {
    width: 100%;
    text-align: center;

    button {
      margin-top: 8px !important;
      background: none;
      border: none;
    }
  }
`

export const EventContent = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  text-align: center;
  align-items: center;
  justify-content: center;

  p {
    margin: 0;
    color: ${({ theme }) => theme.colors.secondary};
    text-align: center;
  }
`
