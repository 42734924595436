import React from 'react'

import { translate } from 'locales'

// Styles
import * as S from './styles'

const TermsPsico: React.FunctionComponent<any> = ({
  autorization,
  age,
}: {
  autorization: boolean
  age: number
}) => {
  return (
    <S.TermContent>
      <span>{translate('medicalRecord.termsPsico.one')}</span>
      <div style={{ marginTop: 25 }} className="subsection">
        <div className="subsection-item">
          <p>{translate('medicalRecord.termsPsico.two')}</p>
        </div>
        <div className="subsection-item">
          <p>{translate('medicalRecord.termsPsico.three')}</p>
        </div>
        <div className="subsection-item">
          <p>{translate('medicalRecord.termsPsico.four')}</p>
        </div>
        {age < 18 && (
          <div className="subsection-item">
            <p>
              {translate('medicalRecord.termsPsico.five')}{' '}
              <span>
                {autorization
                  ? translate('medicalRecord.termsPsico.authorized')
                  : translate('medicalRecord.termsPsico.notAuthorized')}
              </span>{' '}
              {translate('medicalRecord.termsPsico.six')}
            </p>
          </div>
        )}
      </div>
    </S.TermContent>
  )
}

export default TermsPsico
