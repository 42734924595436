import React from 'react'
import { useLocation } from 'react-router-dom'
import { translate } from 'locales'
import './styles.scss'

const Menu: React.FC<{
  openNewTab?: boolean
  setClickedRoute: React.Dispatch<React.SetStateAction<string>>
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>
  redirect: (route: string) => void
}> = ({ openNewTab = false, setClickedRoute, setShowModal, redirect }) => {
  const { pathname } = useLocation()

  const changeSelect = (item: string) => {
    window.localStorage.setItem('@StarBem:lang', item)
    window.location.reload()
  }

  const handleClicked = (route: string) => {
    setClickedRoute(route)
    setShowModal(true)
  }

  return (
    <nav id="menu">
      <ul>
        <li>
          <a
            className={pathname === '/dashboard' ? 'active' : ''}
            onClick={() =>
              openNewTab ? handleClicked('/dashboard') : redirect('/dashboard')
            }
          >
            {translate('header.schedule')}
          </a>
        </li>
        <div className="menu-divider" />
        <li>
          <a
            className={pathname === '/pacientes' ? 'active' : ''}
            onClick={() =>
              openNewTab ? handleClicked('/pacientes') : redirect('/pacientes')
            }
          >
            {translate('header.attendedPatients')}
          </a>
        </li>
        <div className="menu-divider" />
        <li>
          <a
            className={pathname === '/avaliados' ? 'active' : ''}
            onClick={() =>
              openNewTab ? handleClicked('/avaliados') : redirect('/avaliados')
            }
          >
            {translate('header.evaluatedServices')}
          </a>
        </li>
      </ul>
      <div>
        <select
          name="locales"
          title="locales"
          id="locales"
          value={
            window.localStorage.getItem('@StarBem:lang') === 'en' ? 'en' : 'pt'
          }
          onChange={(e) => changeSelect(e.target.value)}
        >
          <option value="pt">🇧🇷 PT</option>
          <option value="en">🇺🇸 EN</option>
        </select>
      </div>
    </nav>
  )
}

export default Menu
