import React, { useRef, useState, useEffect } from 'react'
import { SendRounded } from '@mui/icons-material'
import { socket } from 'websocket'
import { format } from 'date-fns'

import Message from './Message'
import * as S from './styles'
import { RefObject } from '@fullcalendar/core'

interface Props {
  appointmentId: string
  doctorId: string
  doctorName: string
}

const Chat: React.FC<Props> = ({ appointmentId, doctorId, doctorName }) => {
  const chatEndRef: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null)

  const [messages, setMessages] = useState([])
  const [message, setMessage] = useState('')

  const scrollToBottom = () => {
    if (chatEndRef.current) {
      chatEndRef.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const getAllMessages = () => {
    socket.emit('get-messages', {
      appointmentId,
    })
  }

  useEffect(() => {
    setTimeout(() => {
      scrollToBottom()
    }, 1000)

    socket.connect()
    socket.emit('join', {
      appointmentId,
    })

    getAllMessages()

    return () => {
      socket.disconnect()
    }
  }, [])

  useEffect(() => {
    socket.on('messages', (event) => {
      setMessages(event)
    })

    socket.on('message', () => {
      getAllMessages()
    })
  }, [])

  useEffect(() => {
    setTimeout(() => {
      scrollToBottom()
    }, 1000)
  }, [messages])

  const handleSendMessage = () => {
    socket.emit('message', {
      appointmentId,
      senderId: doctorId,
      senderName: doctorName,
      content: message,
    })

    getAllMessages()
    setMessage('')
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault()

      handleSendMessage()
    }
  }

  return (
    <S.ChatContainer>
      <S.ChatContent>
        {messages.map((msg: any) => (
          <Message
            key={msg._id}
            message={msg?.content}
            time={format(new Date(msg?.timestamp), 'HH:mm')}
            name={msg?.senderName}
            isDoctor={msg?.senderId === doctorId}
          />
        ))}

        <div ref={chatEndRef} />
      </S.ChatContent>
      <S.ChatBottom>
        <S.ChatInput
          placeholder="Escreva alguma coisa aqui"
          onChange={(e) => setMessage(e.target.value)}
          value={message}
          onKeyDown={handleKeyDown}
        />
        <S.ChatButton
          onClick={() => handleSendMessage()}
          disabled={message.length < 1 ? true : false}
        >
          <SendRounded fontSize="large" />
        </S.ChatButton>
      </S.ChatBottom>
    </S.ChatContainer>
  )
}

export default Chat
