import React from 'react'
import './style.css'
import { translate } from 'locales'
import { Button } from '../index'

interface StartServiceProps {
  closeModal: () => void
  handleConfirm: () => void
}

const StartService: React.FunctionComponent<StartServiceProps> = ({
  closeModal,
  handleConfirm,
}) => {
  return (
    <>
      <div className="modal-container">
        <h2>{translate('modal.initAttendance')}</h2>
        <p>{translate('modal.initTextAttendance')}</p>
        <div className="buttons-modal">
          <Button
            type="button"
            color="link-danger"
            onClick={() => closeModal()}
            styles={{
              marginTop: 10,
              border: '1px solid red',
              width: 200,
              marginLeft: 5,
            }}
          >
            {translate('general.no')}
          </Button>
          <Button
            type="button"
            color="primary"
            onClick={() => {
              handleConfirm()
              closeModal()
            }}
            styles={{
              marginTop: 10,
              border: '1px solid red',
              width: 200,
              marginLeft: 5,
            }}
          >
            {translate('general.yes')}
          </Button>
        </div>
      </div>
    </>
  )
}

export default StartService
