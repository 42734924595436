import React, { useState } from 'react'
import { alertDanger, alertSuccess } from 'utils/toast'
import { useDropzone } from 'react-dropzone'

import { Button } from 'components'
import api from 'configs/api'

// Styles

import filePdf from 'assets/images/pdf.png'
import file from 'assets/images/filePlus.png'
import { useSWRConfig } from 'hooks/useFetch'
import * as S from './styles'

interface IComponentProps {
  scheduleUUID: string
  nutritional: any
  patientId: any
  setLoading?: any
}

const CardNutritionalGuidance: React.FunctionComponent<IComponentProps> = ({
  scheduleUUID,
  patientId,
  nutritional,
  setLoading,
}) => {
  const [orientation, setOrientation] = useState<any>()
  const { mutate } = useSWRConfig()

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    onDrop: async (acceptedFiles) => {
      const maxSize = 4 * 2048 * 2048
      const acceptFiles = ['application/pdf']

      if (acceptedFiles[0].size > maxSize) {
        alertDanger('A imagem não pode ser maior que 16Mb!')
      } else if (!acceptFiles.includes(acceptedFiles[0].type)) {
        alertDanger('Tipo do arquivo inválido, só é aceito PDF.')
      } else {
        setOrientation(acceptedFiles[0])
      }
    },
  })

  const saveNutritionalGuidance = async () => {
    try {
      const data = new FormData()
      data.append('appointment_id', scheduleUUID)
      data.append('patient_id', patientId)
      data.append('file', orientation)
      setLoading(true)

      await api.post(`doctors/v1/nutritional-guidance`, data)
      mutate(`schedulings/v1/${scheduleUUID}/doctor`)
      setLoading(false)
      return alertSuccess('Orientação nutricional salva com sucesso!')
    } catch (e: any) {
      setLoading(false)
      return alertDanger(
        e?.response?.data.message || 'Erro ao salvar orientação nutricional'
      )
    }
  }

  return (
    <>
      {nutritional ? (
        <S.ContentExam
          onClick={() => window.open(nutritional, '_blank')}
          style={{ cursor: 'pointer' }}
        >
          <img src={filePdf} alt="Imagem de um exame" />
          <span>Arquivo Pdf</span>
        </S.ContentExam>
      ) : (
        <S.ContentExam {...getRootProps()}>
          <input {...getInputProps()} />
          {orientation?.path || nutritional ? (
            <>
              <img src={filePdf} alt="Adicionar arquivo" />

              <h5 style={{ marginTop: '25px', fontSize: '14px' }}>
                {orientation?.path || 'Item já Anexado'}
              </h5>
            </>
          ) : (
            <>
              <img src={file} alt="Adicionar arquivo" />

              <h5 style={{ marginTop: '25px', fontSize: '14px' }}>
                Arquivo .pdf
              </h5>
            </>
          )}
        </S.ContentExam>
      )}
      {!nutritional && (
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            color="primary"
            type="button"
            size="normal"
            iconStyles={{ width: 18, marginRight: 15 }}
            styles={{ marginTop: 10 }}
            disabled={!orientation?.path}
            onClick={() => {
              saveNutritionalGuidance()
            }}
          >
            Salvar
          </Button>
        </div>
      )}
    </>
  )
}

export default CardNutritionalGuidance
