import React, { useEffect, useState } from 'react'

// Context
import { useUser } from 'context/user/user.context'

// Components
import { Header, Card, Loading, Footer } from 'components'
import * as Global from 'global/styles.global'
import { translate } from 'locales/index'
import FormProfile from './Form'
import Photo from './Photo'

// Styles
import * as S from './styles'

// Services
import { findUserData } from './services'

const Profile = () => {
  const { setUserData } = useUser()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    findUserData(setUserData, setLoading)
    // eslint-disable-next-line
  }, [])

  return (
    <Global.Container>
      {loading && <Loading title={translate('profile.loading')} />}
      <Header />
      <S.ContentProfile>
        <Card>
          <h1 className="title-profile">
            {translate('profile.configProfile')}
          </h1>
          <div className="container-config-profile">
            <FormProfile setLoading={setLoading} />
            <Photo setLoading={setLoading} />
          </div>
        </Card>
      </S.ContentProfile>
      <Footer />
    </Global.Container>
  )
}

export default Profile
