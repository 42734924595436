import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Menu } from '@material-ui/icons'
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  Divider,
  Avatar,
} from '@material-ui/core'
import { translate } from 'locales'

import { useSignIn } from '../../context/authentication/signin.context'
import { useUser } from '../../context/user/user.context'

import './styles.scss'

import menuItems from '../../utils/menuItems'

const MenuMobile: React.FC<{
  openNewTab: boolean
  actionNewTab: (route: string) => void
}> = ({ openNewTab = false, actionNewTab }) => {
  const history = useHistory()
  const { logout } = useSignIn()
  const { userData } = useUser()
  const [open, setOpen] = useState(false)

  const toggleDrawer =
    () => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return
      }

      setOpen(!open)
    }

  const handleLogout = () => {
    logout()
    history.replace('/')
  }

  const list = () => (
    <div
      className="list"
      role="presentation"
      onClick={toggleDrawer()}
      onKeyDown={toggleDrawer()}
    >
      <List>
        <ListItem>
          <div className="user-content">
            <Avatar className="user-avatar" src={userData.profile.photo} />
            <span>
              <strong>{userData.name}</strong>
              <br />
              {/* @ts-ignore */}
              <Link
                to="/perfil"
                style={{ textDecoration: 'none', color: '#ff3f72' }}
                target={openNewTab ? '_blank' : '_self'}
                rel={openNewTab ? 'noopener noreferrer' : undefined}
              >
                {' '}
                {translate('header.myProfile')}
              </Link>
            </span>
          </div>
        </ListItem>
        <Divider />
        {menuItems?.map((item) => (
          <ListItem
            button
            onClick={() => actionNewTab(item.route)}
            key={item.id}
          >
            <ListItemText primary={item.name} className="list-item-text" />
          </ListItem>
        ))}
        <Divider />
        <ListItem button onClick={() => handleLogout()}>
          {' '}
          <ListItemText
            primary={translate('header.disconnect')}
            className="list-item-text"
          />
        </ListItem>
      </List>
    </div>
  )

  return (
    <div id="menu-mobile">
      <button
        type="button"
        className="transparent-button"
        onClick={toggleDrawer()}
      >
        <div className="menu-mobile-button">
          <Menu fontSize="large" className="menu-mobile-icon" />
          <Drawer anchor="right" open={open} onClose={toggleDrawer()}>
            {list()}
          </Drawer>
        </div>
      </button>
    </div>
  )
}

export default MenuMobile
