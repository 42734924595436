import React, { useEffect } from 'react'
import { ThemeProvider as ThemeStyled } from 'styled-components'
import { ThemeProvider } from '@mui/material'
import { ToastContainer } from 'react-toastify'

// Theme
import GlobalStyle from 'global/styles.global'
import theme from 'theme/index.theme'
import light from 'theme/light'

// Context
import { SignInProvider } from 'context/authentication/signin.context'
import { UserProvider } from 'context/user/user.context'

// Routes
import Routes from './routes'

function App() {
  useEffect(() => {
    const script = document.createElement('script')
    if (process.env.NODE_ENV === 'production') {
      script.type = 'text/javascript'
      script.async = true

      script.text = `(function(c,l,a,r,i,t,y){
      c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
      t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
      y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
  })(window, document, "clarity", "script", "mg0lpkdjx3");`

      document.head.appendChild(script)
    }
    return () => {
      document.head.removeChild(script)
    }
  }, [])

  return (
    <ThemeStyled theme={light}>
      <ThemeProvider theme={theme}>
        <ToastContainer
          position="top-right"
          autoClose={10000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          toastStyle={{
            fontSize: 14,
          }}
        />
        <UserProvider>
          <SignInProvider>
            <Routes />
          </SignInProvider>
        </UserProvider>
        <GlobalStyle />
      </ThemeProvider>
    </ThemeStyled>
  )
}

export default App
