import React from 'react'
import moment from 'moment'
import { translate } from 'locales'

// Interfaces
import { Title, TextArea } from 'components'
import useStorage from 'hooks/storageSync'
import { IComponentProps } from './interfaces'

// Styles
import * as S from './styles'

const DiagnosticHypothesis: React.FunctionComponent<IComponentProps> = ({
  doctorName,
  scheduleUUID,
}) => {
  const [storage, setStorage]: any = useStorage(scheduleUUID, 'diagnostic')

  // Recupera os valores salvos no storage para a consulta atual na sessão Conduct
  const dataStorage: any = storage?.diagnostic

  const handleChange = (key: string, e: string) => {
    setStorage(key, e)
  }

  return (
    <S.SectionContent>
      <span>{moment(new Date()).format('dddd, DD/MM/YYYY')}</span>
      <p>{`${translate('medicalRecord.by')} ${doctorName}`}</p>
      <div className="subsection">
        <Title text={translate('medicalRecord.diagnosticHypothesis')} />
        <div className="subsection-item">
          <TextArea
            value={dataStorage?.hypothesis}
            name="hypothesis"
            rows={5}
            wrap="hard"
            onChange={(e: any) => handleChange(e.target.name, e.target.value)}
            placeholder={translate('medicalRecord.describe')}
          />
        </div>

        <Title text={translate('medicalRecord.clinicalImpression')} />
        <div className="subsection-item">
          <TextArea
            value={dataStorage?.clinicalImpression}
            name="clinicalImpression"
            rows={5}
            wrap="hard"
            onChange={(e: any) => handleChange(e.target.name, e.target.value)}
            placeholder={translate('medicalRecord.describe')}
          />
        </div>
      </div>
    </S.SectionContent>
  )
}

export default DiagnosticHypothesis
