import React from 'react'
import * as S from './styles'

type IAlert = {
  children: any
  style?: any
}

const Alert: React.FC<IAlert> = ({ children, style }) => {
  return <S.Wrapper style={style}>{children}</S.Wrapper>
}

export default Alert
