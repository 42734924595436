import useSWR, { useSWRConfig } from 'swr'
import api from 'configs/api'

export { useSWRConfig }

interface Props {
  revalidateIfStal?: boolean
  revalidateOnMount?: boolean
  revalidateOnFocus?: boolean
  revalidateOnReconnect?: boolean
  refreshInterval?: number
}

export default ({
  url,
  condition = true,
  options,
}: {
  url: string
  condition?: boolean
  options?: Props
}) => {
  const fetcher = async (url: string) => {
    const response = await api.get(url)

    return response.data
  }

  const { data, error, isLoading, isValidating } = useSWR(
    condition ? url : null,
    fetcher,
    {
      ...options,
    }
  )

  return { data, error: error?.response?.data, isLoading, isValidating }
}
