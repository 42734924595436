import createPersistedState from 'use-persisted-state'

const useStorage = (key: any, session: string) => {
  const useLocalStorage = createPersistedState(`starbem:schedule:${key}`)

  const [storage, setStorage]: any = useLocalStorage({})

  const setValue = (name: string, value: string) => {
    const data = {
      ...storage,
      [session]: {
        ...storage?.[session],
        [name]: value,
      },
    }

    setStorage(data)
  }

  return [storage, setValue, setStorage]
}

export default useStorage
