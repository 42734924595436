import styled from 'styled-components'

export const ContentProfile = styled.div`
  width: 100%;
  max-width: 1200px;
  position: relative;
  padding: 90px 16px 16px;

  h1.title-profile {
    font-size: 2.4rem;
    line-height: 3rem;
    color: ${({ theme }) => theme.colors.black};
  }

  .container-config-profile {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    margin-top: 50px;

    @media screen and (min-width: 750px) {
      display: grid;
      grid-template-columns: 0.75fr 1.25fr;
    }
  }

  @media screen and (min-width: 961px) {
    margin: 30px auto;
  }
`

export const FormContent = styled.div`
  .form-group {
    margin-top: 1rem;

    label {
      font-size: 1.6rem;
    }

    select {
      height: 48px;
      border: 1px solid ${({ theme }) => theme.colors.gray20};
      border-radius: ${({ theme }) => theme.radius.medium}px;
      padding: 0 16px;
      font-size: 1.4rem;
    }
  }

  .content-inputs {
    display: flex;
    flex-direction: column;

    .content-input {
      padding: 2px 0;
      margin-right: 10px;

      @media screen and (min-width: 750px) {
        margin-right: 0;
        width: 95%;
      }
    }

    @media screen and (min-width: 750px) {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  .new-password-demands {
    margin-top: 16px;

    p {
      margin-top: 16px;
    }

    ul {
      margin-top: 8px;
      list-style: none;

      li {
        padding-left: 8px;
      }
    }
  }

  .button-show-password {
    position: absolute;
    top: 50%;
    right: 32px;
    transform: translate(-50%);
    border: none;

    svg {
      width: 20px;
      height: 20px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: ${({ theme }) => theme.colors.gray50};
    }
  }

  .area-button {
    width: 100%;
    max-width: 150px;
    margin-top: 30px;
  }
`

export const PhotoProfile = styled.div`
  padding: 0;
  margin-bottom: 2rem;
  padding-left: 0;

  .content-photo {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-bottom: 30px;

    .photo-content-wrapper {
      width: 102px;
      height: 102px;

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        border: 1px solid ${({ theme }) => theme.colors.gray10};
      }

      .edit-icon {
        width: 3rem;
        height: 3rem;
        background: ${({ theme }) => theme.colors.white};
        box-shadow: 0.5rem 0.5rem 0.5rem rgba(0, 0, 0, 0.1);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        position: absolute;
        margin-top: -30px;
        margin-left: 70px;

        svg {
          color: ${({ theme }) => theme.colors.secondary};
        }
      }

      .photo-label {
        text-align: center;
        font-size: 1.2rem;
        margin-top: 10px;
        color: ${({ theme }) => theme.colors.gray50};
        display: none;

        @media screen and (min-width: 961px) {
          display: block;
        }
      }
    }

    @media screen and (min-width: 961px) {
      justify-content: flex-start;
    }
  }

  @media screen and (min-width: 961px) {
    margin-bottom: 0;
    padding-left: 50px;
  }
`
