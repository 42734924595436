import io from 'socket.io-client'

export const socket = io('http://localhost:3005', {
  transports: ['websocket'],
  autoConnect: false,
  secure: true,
  reconnection: true,
})

socket.on('connect_error', () => {
  socket.connect()
  console.info('WEBSOCKET connect_error')
})

socket.on('connect', () => {
  console.info('WEBSOCKET - CONNECTED')
})

socket.on('disconnect', (reason) => {
  console.info('WEBSOCKET DISCONECTED', socket.connected) // false
  if (reason === 'io server disconnect') {
    // the disconnection was initiated by the server, you need to reconnect manually
    socket.connect()
  }
})
