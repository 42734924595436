import React from 'react'
import moment from 'moment'
import api from 'configs/api'
import { alertSuccess, alertDanger } from 'utils/toast'
import { translate } from 'locales'

// Interfaces
import { IDoctorSchedule } from './interfaces'

export const findScheduleConfiguration = async (
  setDoctorSchendule: React.Dispatch<React.SetStateAction<IDoctorSchedule[]>>,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
  try {
    const locales = window.localStorage.getItem('@StarBem:lang')

    const dataInStorage: any = window.localStorage.getItem('@StarBem:user')
    const dataPase = JSON.parse(dataInStorage)

    const find = await api.get(
      `/doctors/v2/schedule?doctor_id=${dataPase.id}`,
      {
        headers: {
          'Accept-Language': locales || 'pt',
        },
      }
    )
    const items = find.data.schedules

    const scheduleSanitized: IDoctorSchedule[] = items.map((item: any) => ({
      id: item.id,
      start: item.start_time,
      end: item.end_time,
      occupation: {
        uuid: item.specialty_id,
        name: item.specialty_name,
      },
      status: 'confirmed',
    }))

    setDoctorSchendule(scheduleSanitized)
    setLoading(false)
  } catch (err) {
    console.error(err)
    setLoading(false)
  }
}

export const handleDateClick = (
  eventInfo: any,
  setNewSchedule: React.Dispatch<React.SetStateAction<IDoctorSchedule>>,
  interval?: any
) => {
  const startTime = moment
    .utc(eventInfo.dateStr)
    .format('YYYY-MM-DD[T]HH:mm:ss[Z]')

  let endTime: Date | object | string = moment(eventInfo.dateStr)
    .add(interval || 30, 'minutes')
    .toDate()

  endTime = moment.utc(endTime).format('YYYY-MM-DD[T]HH:mm:ss[Z]')

  const recentlyCreatedSchedule: IDoctorSchedule = {
    start: startTime,
    end: endTime.toString(),
    overlap: false,
    status: 'pendent',
  }

  setNewSchedule(recentlyCreatedSchedule)
}

export const handleSaveScheduleConfiguration = async (
  occupations: any,
  selectedOccupation: any,
  setSelectedOccupation: React.Dispatch<React.SetStateAction<any>>,
  country: any,
  selectedCountry: any,
  setSelectedCountry: React.Dispatch<React.SetStateAction<any>>,
  newSchedule: IDoctorSchedule,
  setNewSchedule: React.Dispatch<React.SetStateAction<IDoctorSchedule>>,
  doctorSchedule: any,
  setDoctorSchedule: React.Dispatch<React.SetStateAction<any>>,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  repeatSchedule: any,
  selectedGroups: string
) => {
  if (moment.utc(newSchedule.end).format('HH:mm:ss') > '23:45:00') {
    return alertDanger(translate('errors.timeLimit'))
  }

  const countryData: any[] = []

  if (selectedCountry?.includes('BR') && selectedCountry?.includes('US')) {
    countryData.push(...selectedCountry)
  } else if (selectedCountry?.includes('BR')) {
    countryData.push('PT', 'BR')
  } else if (selectedCountry?.includes('US')) {
    countryData.push(...selectedCountry)
  }

  let groupsExists: any = {}

  if (selectedGroups) {
    if (selectedGroups === 'Padrão') {
      groupsExists = {
        groups: [],
      }
    } else {
      groupsExists = {
        groups: [selectedGroups],
      }
    }
  }

  setLoading(true)
  try {
    const save: any = await api.post('/doctors/v1/schedule', {
      specialty_id: selectedOccupation.id,
      day: moment.utc(newSchedule.start).format('YYYY-MM-DD'),
      start_time: moment.utc(newSchedule.start).format('HH:mm:ss'),
      end_time: moment.utc(newSchedule.end).format('HH:mm:ss'),
      recurrence: repeatSchedule,
      countries: countryData,
      ...groupsExists,
    })

    if (Array.isArray(save.data.schedule)) {
      const newSchedulesToAdd: Array<any> = []

      save.data.schedule.map((item: any) => {
        newSchedulesToAdd.push({
          ...newSchedule,
          id: item.id,
          start: `${moment.utc(item.day).format('YYYY-MM-DD')}T${moment
            .utc(item.start_time)
            .format('HH:mm:ss')}Z`,
          end: `${moment.utc(item.day).format('YYYY-MM-DD')}T${moment
            .utc(item.end_time)
            .format('HH:mm:ss')}Z`,
          occupation: {
            name: selectedOccupation.name,
            id: selectedOccupation.id,
          },
          status: 'confirmed',
        })
      })

      setDoctorSchedule([...doctorSchedule, ...newSchedulesToAdd])

      alertSuccess(translate('success.timeReserved'))

      setSelectedOccupation(occupations[0])
      setNewSchedule({})
      setLoading(false)
    } else {
      setLoading(false)
      alertDanger(save.data?.message)
    }
  } catch (err: any) {
    alertDanger(
      err?.response?.data?.message || translate('errors.errorSaveTimes')
    )
    // setDoctorSchedule([
    //   ...doctorSchedule,
    //   {
    //     ...newSchedule,
    //     id: save.data.schedule.id,
    //     occupation: {
    //       name: selectedOccupation.title,
    //       uuid: selectedOccupation.key,
    //     },
    //     status: 'confirmed',
    //   },
    // ])
    setLoading(false)
  }
  return true
}

export const handleDeleteSchedule = async (
  uuid: string,
  doctorSchedule: IDoctorSchedule[],
  setDoctorSchedule: React.Dispatch<React.SetStateAction<IDoctorSchedule[]>>,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
  setLoading(true)

  try {
    const del = await api.delete(`/doctors/v1/schedule/slot/${uuid}`)

    const doctorScheduleUpdated = doctorSchedule.filter(
      (item: any) => item.id !== uuid
    )

    setDoctorSchedule(doctorScheduleUpdated)

    alertSuccess(del.data.message)

    setLoading(false)
  } catch (err: any) {
    setLoading(false)
    alertDanger(
      err?.response?.data?.message || translate('errors.errorDeleteTimes')
    )
  }
}
