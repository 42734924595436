import React, { useContext, useState, createContext } from 'react'

// Types
import { UserProps, UserProviderProps, UserStateProps } from './user.types'

const defaultUser: UserProps = {
  id: '',
  name: '',
  email: '',
  status: 0,
  statusDescription: '',
  profile: {
    gender: '',
    photo: '',
    document: '',
    cellphone: '',
    professional_document_type: '',
    professional_document_uf: '',
    professional_document_number: '',
    specialty: '',
  },
  specialties: [],
  token: '',
}

const UserContext = createContext<UserStateProps>({
  userData: defaultUser,
  setUserData: () => {},
  verifyUserStorage: () => {},
})

const UserProvider: React.FunctionComponent<UserProviderProps> = ({
  children,
}) => {
  const [userData, setUserData] = useState<UserProps>(defaultUser)

  const verifyUserStorage = (): void => {
    const data = window.localStorage.getItem('@StarBem:user')

    if (data) {
      setUserData(JSON.parse(data))
    }
  }

  const value: UserStateProps = {
    userData,
    setUserData,
    verifyUserStorage,
  }

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}

const useUser = () => {
  return useContext(UserContext)
}

export { UserProvider, useUser }
