export const convertHeight = (height: number) => {
  if (height) {
    const parseHeight = String(height).replace(/[^\d]+/g, '')
    const convert = parseFloat(parseHeight) / 100

    if (parseHeight.length === 2) {
      return (parseFloat(parseHeight) / 10).toFixed(2)
    }
    return convert
  }

  return 0
}

export const calcIMC = (height: number, weight: number) => {
  if (height && weight) {
    const newHeight = Number(convertHeight(height))

    const imc = weight / (newHeight * newHeight)
    return imc.toFixed(2)
  }

  return 0
}
