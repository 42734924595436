import { I18n } from 'i18n-js'

import pt from './translations/pt.json'
import en from './translations/en.json'

const locales = window.localStorage.getItem('@StarBem:lang')

const i18n = new I18n({
  en,
  pt,
})

i18n.locale = locales || 'pt'

export const translate = (key: string | string[]) => i18n.t(key)
