import styled, { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html {
    height: -webkit-fill-available;
  }

  body {
    font-family: 'Mulish', sans-serif !important;
    min-height: -webkit-fill-available;
    min-height: 100vh;
  }

  #root {
    background: #f0f2f8;
  }

  input,
  button,
  textarea,
  fieldset,
  legend,
  label,
  p,
  span {
    font-family: 'Mulish', sans-serif;
    font-size: 1.4rem !important;
    outline: none;
  }

  p {
    white-space: pre-wrap;
  }

  thead > tr > th {
    font-family: 'Mulish', sans-serif;
    font-size: 1.6rem !important;
    font-weight: 500;
    line-height: 2rem;
    color: var(--color-black);
  }

  tbody > tr > th,
  td {
    font-family: 'Mulish', sans-serif;
    font-size: 1.4rem !important;
    font-weight: normal;
    line-height: 1.6rem;
    color: var(--color-gray-80) !important;
  }

  hr {
    border: 0.3px solid var(--color-gray-30) !important;
  }

  li {
    font-size: 1.4rem !important;
  }

  div,
  section,
  article,
  img {
    outline: none;
  }

  #root {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  :root {
    font-size: 60%;
  }
`

export const Container = styled.section`
  width: 100%;
  min-height: 100vh;
  padding: 0;
  margin: 0;
  position: relative;
  background: #f9f9f9;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
`

export const Line = styled.div`
  width: 100%;
  height: 1px;
  background: #d9dce6;
  margin-top: 30px;
  margin-bottom: 30px;
`

export default GlobalStyle
