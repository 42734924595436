/* eslint-disable react/jsx-one-expression-per-line */
import React, { useState, useEffect } from 'react'

import './Style.css'

import CloseIcon from '@material-ui/icons/Close'
import { Button, TextArea } from 'components'
import useStorage from 'hooks/storageSync'
import { translate } from 'locales'
import { supplements } from '../utils'

const TagSelectorNutri: React.FunctionComponent<any> = ({
  scheduleUUID,
}: any) => {
  const [storage, setStorage]: any = useStorage(
    scheduleUUID,
    'nutritional_anamnese'
  )
  const [selectTag, setSelectTag] = useState<any>()

  const addTag = (tag: string): void => {
    const updatedTags = [...selectTag, tag]
    setSelectTag(updatedTags)
    setStorage('supplements', updatedTags)
  }

  const removeTag = (tag: string): void => {
    const filterSelected = selectTag.filter((item: any) => item !== tag)
    setSelectTag(filterSelected)
    setStorage('supplements', filterSelected)
  }

  const handleChange = (key: string, e: string) => {
    setStorage(key, e)
  }

  useEffect(() => {
    const initialTags = storage.nutritional_anamnese?.supplements || []
    setSelectTag(initialTags)
  }, [])

  return (
    <div style={{ marginTop: 20 }}>
      <div className="tag-container">
        {selectTag?.map((tag: string) => (
          <Button
            key={tag}
            color="tag-active"
            type="button"
            className="tag-button"
            onClick={() => {
              removeTag(tag)
            }}
          >
            <span>{tag}</span>
            <CloseIcon fontSize="small" />
          </Button>
        ))}
      </div>
      {supplements && (
        <div className="tag-container">
          {supplements?.map((tag: string) => (
            <Button
              color="tag"
              type="button"
              className="tag-button"
              key={tag}
              onClick={() => {
                addTag(tag)
              }}
              disabled={selectTag?.includes(tag)}
            >
              {tag}
            </Button>
          ))}
        </div>
      )}
      <h4>{translate('nutritionalReason.comments')}:</h4>
      <TextArea
        name="supplements_comments"
        value={storage.nutritional_anamnese?.supplements_comments}
        placeholder={translate('medicalRecord.describe')}
        onChange={(e: any) => handleChange(e.target.name, e.target.value)}
        wrap="hard"
      />
    </div>
  )
}

export default TagSelectorNutri
