/* eslint-disable react/jsx-one-expression-per-line */
import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Timeline from '@material-ui/lab/Timeline'
import TimelineItem from '@material-ui/lab/TimelineItem'
import TimelineSeparator from '@material-ui/lab/TimelineSeparator'
import TimelineConnector from '@material-ui/lab/TimelineConnector'
import TimelineContent from '@material-ui/lab/TimelineContent'
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent'
import TimelineDot from '@material-ui/lab/TimelineDot'
import Typography from '@material-ui/core/Typography'
import moment from 'moment'
import { Pagination } from '@material-ui/lab'

import { translate } from 'locales'

import { CardDate, Loading } from 'components'
import noData from 'assets/images/no-data.svg'
import { useUser } from 'context/user/user.context'
import { parseISO, differenceInMinutes } from 'date-fns'
import TimeLineResumePsicology from './TimeLineResumePsicology'

import { findAllMedicalRecordsPsico } from './services'

import * as S from './styles'
import * as N from '../New/styles'

moment.locale('pt-br', {
  monthsShort: [
    'Jan',
    'Fev',
    'Mar',
    'Abr',
    'Mai',
    'Jun',
    'Jul',
    'Ago',
    'Set',
    'Out',
    'Nov',
    'Dez',
  ],
})

const useStyles = makeStyles((theme: any) => ({
  timeline: {
    padding: 0,
  },
  left: {
    flex: 'none',
    paddingLeft: 0,
  },
  right: {
    paddingRight: 0,
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    '@media(max-width: 740px)': {
      flexDirection: 'column',
      gap: '10px',
    },
  },
}))

interface IResume {
  userUUID: string
}

const Psico: React.FunctionComponent<IResume> = ({ userUUID }) => {
  const classes = useStyles()
  const { userData } = useUser()

  const [records, setRecords] = useState<any>({})
  const [loading, setLoading] = useState(true)

  const [totalPages, setTotalPages] = useState(0)
  const [page, setPage] = useState(1)

  useEffect(() => {
    const specialties = userData?.specialties

    const specialty =
      specialties &&
      specialties.filter((item: any) => item.name === 'Psicólogo')[0]

    if (specialty) {
      findAllMedicalRecordsPsico(
        userUUID,
        specialty.id,
        setRecords,
        setLoading,
        page,
        setTotalPages
      )
    }
    // eslint-disable-next-line
  }, [userData, page])

  const timeConsultation = (e: any, t: any) => {
    const startConsultation = parseISO(e)
    const endConsultation = parseISO(t)

    const consultationDuration = differenceInMinutes(
      endConsultation,
      startConsultation
    )
    return consultationDuration
  }

  return (
    <>
      {loading && <Loading title={translate('medicalRecord.loadingData')} />}
      <Timeline align="left" className={classes.timeline}>
        {records.medicalRecordsPsico &&
          Object.keys(records.medicalRecordsPsico).length === 0 && (
            <N.NoData>
              <img
                src={noData}
                alt="Icone simbolizando que não existe prontuarios a serem exibidas"
              />
              <span>{translate('medicalRecord.notMedicalRecord')}</span>
            </N.NoData>
          )}

        {records.medicalRecordsPsico &&
          Object.keys(records.medicalRecordsPsico).map((item) => (
            <TimelineItem key={item}>
              <TimelineOppositeContent className={classes.left}>
                <CardDate
                  day={moment.utc(item).format('DD')}
                  month={moment.utc(item).format('MMM')}
                  year={moment.utc(item).format('YYYY')}
                />
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent className={classes.right}>
                {records.medicalRecordsPsico[item].map((i: any) => (
                  <Accordion key={i.id}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id={i.id}
                    >
                      <div
                        className={classes.heading}
                        style={{
                          marginTop: 0,
                          display: 'flex',
                          flex: 1,
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <Typography>
                          {`${translate('medicalRecord.by')} Dr(a). ${
                            i.scheduling?.doctor?.name
                          }`}

                          <Typography>
                            {`${translate('schedule.scheduleDate')}: ${moment(
                              i.scheduling.from_date_time
                            ).format('DD/MM/YYYY')}`}
                          </Typography>
                        </Typography>
                      </div>
                      <div>
                        <Typography>
                          {timeConsultation(
                            i.scheduling?.from_date_time,
                            i.scheduling?.to_date_time
                            // eslint-disable-next-line react/jsx-one-expression-per-line
                          )}{' '}
                          min {`(${i.scheduling?.specialty_name})`}
                        </Typography>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails style={{ display: 'block', padding: 0 }}>
                      <TimeLineResumePsicology
                        history={i}
                        setLoading={setLoading}
                      />
                    </AccordionDetails>
                  </Accordion>
                ))}
              </TimelineContent>
            </TimelineItem>
          ))}
        <S.ContentPagination>
          {totalPages > 1 && (
            <Pagination
              count={totalPages}
              onChange={(event: React.ChangeEvent<unknown>, value: number) => {
                setPage(value)
              }}
            />
          )}
        </S.ContentPagination>
      </Timeline>
    </>
  )
}

export default Psico
