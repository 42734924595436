import styled from 'styled-components'

export const ContentPatients = styled.div`
  width: 100%;
  max-width: 1200px;
  position: relative;
  padding: 90px 16px 16px;

  .patients-top {
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: 740px) {
      flex-direction: column;
    }

    h1.title-patients {
      font-size: 2.4rem;
      line-height: 3rem;
      color: ${({ theme }) => theme.colors.black};
    }
  }
  .search-patients {
    display: flex;

    button {
      margin-left: 15px;
    }
  }
  @media screen and (min-width: 961px) {
    margin: 20px auto;
  }
`

export const TablePatients = styled.div`
  .primary-col {
    color: ${({ theme }) => theme.colors.black} !important;
    @media screen and (max-width: 740px) {
      min-width: 200px;
    }
  }
  @media screen and (max-width: 740px) {
    overflow: auto;
  }
`
export const Pagination = styled.div`
  width: 100%;
  padding: 15px 40px;
  align-items: flex-end;
  display: flex;
  justify-content: flex-end;
`

export const ContentHeader = styled.div`
  display: flex;
  justify-content: end;
`
export const ContentInputs = styled.div`
  width: 50%;
  display: flex;
  justify-self: end;
  align-items: center;

  @media screen and (max-width: 540px) {
    width: 100%;
    flex-wrap: wrap;
  }
`
