/* eslint-disable no-unsafe-optional-chaining */
import React, { useEffect, useMemo, useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { useFormik } from 'formik'
import {
  IconButton,
  InputAdornment,
  TextField as Input,
  OutlinedInput,
  FormControl,
  InputLabel,
} from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import { translate } from 'locales'

// Configs
import api from 'configs/api'

// Utils
import { alertDanger, alertSuccess } from 'utils/toast'

// Contexts
import { useSignIn } from 'context/authentication/signin.context'
import { useUser } from 'context/user/user.context'

// Components
import { Button, Loading } from 'components'

import ReCAPTCHA from 'react-google-recaptcha'

// Images
import logo from 'assets/images/logo.svg'
import * as S from './styles'
import { schemaLogin } from './validations'

const Login = () => {
  const history = useHistory()
  const { signInSuccess, verifySignIn } = useSignIn()
  const { setUserData } = useUser()

  const [loading, setLoading] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const recaptcha = useRef<any>(null)

  useEffect(() => {
    if (verifySignIn()) {
      history.push('/dashboard')
    }
  }, [history, verifySignIn])

  const initialValues = useMemo(
    () => ({
      email: '',
      password: '',
    }),
    []
  )

  const changeSelect = (item: string) => {
    window.localStorage.setItem('@StarBem:lang', item)
    window.location.reload()
  }

  const formik = useFormik({
    initialValues,
    validationSchema: schemaLogin,
    onSubmit: () => {
      setLoading(true)
      recaptcha.current.execute()
    },
  })

  const handleReCAPTCHA = async () => {
    try {
      const response = await api.post('doctors/v1/users/login', {
        email: formik.values.email,
        password: formik.values.password,
      })

      const { doctor, token: userToken } = response.data
      window.localStorage.setItem('@StarBem:user', JSON.stringify(doctor))

      setUserData(doctor)
      signInSuccess(userToken)
      history.push('/dashboard')
      alertSuccess(`${translate('login.welcome')}, ${doctor?.name}!`)
    } catch (err: any) {
      const { data } = err?.response
      alertDanger(
        data?.message || 'Ocorreu um erro inesperado, tente novamente!'
      )
    } finally {
      setLoading(false)
    }
  }

  return (
    <S.Container>
      <S.ContentSlect>
        <select
          name="locales"
          id="locales"
          value={
            window.localStorage.getItem('@StarBem:lang') === 'en' ? 'en' : 'pt'
          }
          onChange={(e) => changeSelect(e.target.value)}
        >
          <option value="pt">🇧🇷 PT</option>
          <option value="en">🇺🇸 EN</option>
        </select>
      </S.ContentSlect>
      {loading && <Loading title={translate('login.loading')} />}
      <S.Logo>
        <img
          src={logo}
          alt="Logo em imagem que representa a marca da Starbem"
        />
      </S.Logo>
      <S.Card>
        <form onSubmit={formik.handleSubmit}>
          <Input
            id="outlined-basic"
            label="Email"
            type="email"
            name="email"
            variant="outlined"
            className="input-default"
            color="secondary"
            onChange={formik.handleChange}
            value={formik.values.email}
            error={!!formik.errors.email}
            helperText={formik.errors.email}
          />
          <FormControl fullWidth variant="outlined" style={{ marginTop: 10 }}>
            <InputLabel htmlFor="outlined-adornment-password" color="secondary">
              {translate('login.password')}
            </InputLabel>
            <OutlinedInput
              label={translate('login.password')}
              id="outlined-basic"
              type={showPassword ? 'text' : 'password'}
              value={formik.values.password}
              name="password"
              onChange={formik.handleChange}
              color="secondary"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onMouseDown={() => {
                      setShowPassword(!showPassword)
                    }}
                    edge="end"
                    style={{ outline: 'none' }}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          <S.AreaButton>
            <Button
              color="primary"
              type="submit"
              disabled={!formik.isValid}
              styles={{ width: '100%', height: 50 }}
            >
              Entrar
            </Button>
            <ReCAPTCHA
              ref={recaptcha}
              size="invisible"
              sitekey={`${process.env.REACT_APP_RECAPTCHA}`}
              onChange={handleReCAPTCHA}
            />
          </S.AreaButton>
        </form>
      </S.Card>
    </S.Container>
  )
}

export default Login
