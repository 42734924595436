import { translate } from 'locales'

export const menuItems = [
  {
    id: 'general',
    title: translate('menuItems.general'),
  },
  {
    id: 'psychotherapy',
    title: translate('menuItems.psychotherapy'),
  },
  {
    id: 'nutrition',
    title: translate('menuItems.nutrition'),
  },
  {
    id: 'accompaniments',
    title: translate('menuItems.accompaniments'),
  },
]
