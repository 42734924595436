import React from 'react'
import moment from 'moment'

import { translate } from 'locales'

// Styles
import { Title, Button } from 'components'
import * as S from './styles'

// Interfaces
import { IComponentProps } from './interfaces'

const Prescriptions: React.FunctionComponent<IComponentProps> = ({
  doctorName,
  prescriptions,
}) => {
  return (
    <S.SectionContent>
      <span>{moment(new Date()).format('dddd, DD/MM/YYYY')}</span>
      <p>{`${translate('medicalRecord.by')} ${doctorName}`}</p>

      <div className="subsection">
        <Title text={translate('medicalRecord.prescriptions')} />
        <div className="cards-prescriptions">
          {prescriptions &&
            prescriptions?.map((prescription: any) => (
              <S.CardPrescriptionExams key={prescription.uuid}>
                <h1>{prescription.codigoValidacao}</h1>
                <span>
                  {`${translate('medicalRecord.issuedOn')} ${moment(
                    prescription.createdAt
                  ).format('DD/MM/YYYY')}`}
                </span>
                <Button
                  type="button"
                  color="primary-light"
                  onClick={() =>
                    window.open(prescription.prescriptionPDF, '_blank')
                  }
                  styles={{ width: '100%' }}
                >
                  {translate('medicalRecord.view')}
                </Button>
              </S.CardPrescriptionExams>
            ))}
        </div>
      </div>
    </S.SectionContent>
  )
}

export default Prescriptions
