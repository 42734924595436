import React from 'react'

import { translate } from 'locales'

import * as S from './styles'

const defaultMessage = translate('medicalRecord.uninformed')

const TimeLineFollowUp = ({ history }: any) => {
  return (
    <S.TimeResumeContent>
      <section className="section-resume">
        <div className="title-resume">
          {translate('medicalRecord.accompanimentWithNurse')}
        </div>
        <div className="content-resume">
          <S.Item>
            <strong>{translate('medicalRecord.accompanimentReason')}</strong>

            <p style={{ margin: '10px 0' }}>
              - {history?.reason_accompaniment || defaultMessage}
            </p>
          </S.Item>
          <S.Item>
            <strong>{`${translate('medicalRecord.anotations')}:`}</strong>

            {history?.annotation.map((i: any) => (
              <p style={{ margin: '10px 0' }} key={i.id}>
                - {i.annotation || defaultMessage}
              </p>
            ))}
          </S.Item>
        </div>
      </section>
    </S.TimeResumeContent>
  )
}

export default TimeLineFollowUp
