import styled from 'styled-components'

export const Container = styled.div`
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors.secondary};

  .top,
  .bottom {
    padding-top: 5px;
    padding-left: 10px;
    padding-right: 10px;

    span {
      font-size: 12px !important;
      color: ${({ theme }) => theme.colors.white} !important;
    }
  }

  .top {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 2px;

    .text-day {
      font-size: 18px !important;
      font-weight: bold;
      color: ${({ theme }) => theme.colors.white} !important;
    }
  }

  .bottom {
    padding-top: 2px;
    padding-bottom: 5px;
  }

  .divisor {
    width: 100%;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.white};
  }
`
