import styled from 'styled-components'

export const Container = styled.footer`
  width: 100%;
  height: 50px;
  border-top: 1px solid ${({ theme }) => theme.colors.gray10};
  display: flex;
  align-items: center;
  justify-content: center;

  .legal {
    font-size: 1.2rem;
    color: ${({ theme }) => theme.colors.gray80};
    text-align: center;
  }

  @media screen and (max-width: 740px) {
    .legal {
      font-size: 1.2rem;
      color: ${({ theme }) => theme.colors.gray80};
      width: 90%;
      height: 50px;
      text-align: center;
    }
  }
`
