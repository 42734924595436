import React from 'react'
import moment from 'moment'
import { translate } from 'locales'

// Styles
import { Title, TextArea } from 'components'
import useStorage from 'hooks/storageSync'
import * as S from './styles'

// Interfaces
import { IComponentProps } from './interfaces'

const Conduct: React.FunctionComponent<IComponentProps> = ({
  doctorName,
  scheduleUUID,
}) => {
  const [storage, setStorage]: any = useStorage(scheduleUUID, 'conduct')

  // Recupera os valores salvos no storage para a consulta atual na sessão Conduct
  const dataStorage: any = storage?.conduct

  const handleChange = (key: string, e: string) => {
    setStorage(key, e)
  }

  return (
    <S.SectionContent>
      <span>{moment(new Date()).format('dddd, DD/MM/YYYY')}</span>
      <p>{`${translate('medicalRecord.by')} ${doctorName}`}</p>

      <div className="subsection">
        <Title text={`${translate('medicalRecord.conduct')} *`} />
        <div className="subsection-item">
          <TextArea
            value={dataStorage?.conduct}
            name="conduct"
            rows={5}
            wrap="hard"
            onChange={(e: any) => handleChange(e.target.name, e.target.value)}
            placeholder={translate('medicalRecord.describe')}
          />
        </div>
      </div>
    </S.SectionContent>
  )
}

export default Conduct
