import React, { useState } from 'react'
import { alertDanger, alertSuccess } from 'utils/toast'
import { useDropzone } from 'react-dropzone'
import { translate } from 'locales'

import { Button } from 'components'
import api from 'configs/api'

// Styles

import filePdf from 'assets/images/pdf.png'
import file from 'assets/images/filePlus.png'
import { useSWRConfig } from 'hooks/useFetch'
import * as S from './styles'

interface IComponentProps {
  scheduleUUID: string
  recipe: any
  patientId: any
  setLoading?: any
}

const CardRecipe: React.FunctionComponent<IComponentProps> = ({
  scheduleUUID,
  patientId,
  recipe,
  setLoading,
}) => {
  const [recipeFile, setRecipeFile] = useState<any>()
  const [selectedImage, setSelectedImage] = useState<any>(null)
  const { mutate } = useSWRConfig()

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    onDrop: async (acceptedFiles) => {
      const maxSize = 4 * 2048 * 2048
      // const acceptFiles = ['application/pdf']

      if (acceptedFiles[0].size > maxSize) {
        alertDanger('A imagem não pode ser maior que 16Mb!')
      } else {
        setRecipeFile(acceptedFiles[0])
      }

      if (file) {
        const reader = new FileReader()
        reader.readAsDataURL(acceptedFiles[0])
        reader.onloadend = () => {
          setSelectedImage(reader.result)
        }
        setSelectedImage(acceptedFiles[0])
      }
    },
  })

  const saveRecipe = async () => {
    try {
      const data = new FormData()
      data.append('appointment_id', scheduleUUID)
      data.append('patient_id', patientId)
      data.append('file', recipeFile)
      setLoading(true)

      await api.post(`doctors/v1/recipe`, data)
      mutate(`schedulings/v1/${scheduleUUID}/doctor`)
      setLoading(false)
      return alertSuccess(translate('success.recipe'))
    } catch (e: any) {
      setLoading(false)
      return alertDanger(
        e?.response?.data.message || translate('errors.recipe')
      )
    }
  }

  return (
    <>
      {recipe ? (
        <S.ContentExam
          onClick={() => window.open(recipe, '_blank')}
          style={{ cursor: 'pointer' }}
        >
          <img src={recipe} alt="Anexo da Receita" />
          <span> {translate('medicalRecord.file')}</span>
        </S.ContentExam>
      ) : (
        <S.ContentExam {...getRootProps()}>
          <input {...getInputProps()} />
          {recipeFile?.path || recipe ? (
            <>
              <img
                src={
                  recipeFile.type === 'application/pdf'
                    ? filePdf
                    : selectedImage
                }
                alt="Adicionar arquivo"
              />

              <h5 style={{ marginTop: '25px', fontSize: '14px' }}>
                {recipeFile?.path || 'Item já Anexado'}
              </h5>
            </>
          ) : (
            <>
              <img src={file} alt="Adicionar arquivo" />

              <h5 style={{ marginTop: '25px', fontSize: '14px' }}>
                {translate('medicalRecord.file')}
              </h5>
            </>
          )}
        </S.ContentExam>
      )}
      {!recipe && (
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            color="primary"
            type="button"
            size="normal"
            iconStyles={{ width: 18, marginRight: 15 }}
            styles={{ marginTop: 10 }}
            disabled={!recipeFile?.path}
            onClick={() => {
              saveRecipe()
            }}
          >
            {translate('button.save')}
          </Button>
        </div>
      )}
    </>
  )
}

export default CardRecipe
