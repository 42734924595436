import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Timeline from '@material-ui/lab/Timeline'
import TimelineItem from '@material-ui/lab/TimelineItem'
import TimelineSeparator from '@material-ui/lab/TimelineSeparator'
import TimelineConnector from '@material-ui/lab/TimelineConnector'
import TimelineContent from '@material-ui/lab/TimelineContent'
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent'
import TimelineDot from '@material-ui/lab/TimelineDot'
import Typography from '@material-ui/core/Typography'
import moment from 'moment'

import { translate } from 'locales'

import { CardDate, Loading } from 'components'
import noData from 'assets/images/no-data.svg'
import { useUser } from 'context/user/user.context'

import { findAllMedicalRecordsFollowUp } from './services'

import * as N from '../New/styles'

import TimeLineFollowUp from './TimeLineFollowUp'

moment.locale('pt-br', {
  monthsShort: [
    'Jan',
    'Fev',
    'Mar',
    'Abr',
    'Mai',
    'Jun',
    'Jul',
    'Ago',
    'Set',
    'Out',
    'Nov',
    'Dez',
  ],
})

const useStyles = makeStyles((theme: any) => ({
  timeline: {
    padding: 0,
  },
  left: {
    flex: 'none',
    paddingLeft: 0,
  },
  right: {
    paddingRight: 0,
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    '@media(max-width: 740px)': {
      flexDirection: 'column',
      gap: '10px',
    },
  },
}))

interface IResume {
  userUUID: string
}

const FollowUp: React.FunctionComponent<IResume> = ({ userUUID }) => {
  const classes = useStyles()
  const { userData } = useUser()

  const [records, setRecords] = useState<any>({})
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    findAllMedicalRecordsFollowUp(userUUID, setRecords, setLoading)

    // eslint-disable-next-line
  }, [userData])

  return (
    <>
      {loading && <Loading title={translate('medicalRecord.loadingData')} />}
      <Timeline align="left" className={classes.timeline}>
        {records && Object.keys(records).length === 0 && (
          <N.NoData>
            <img
              src={noData}
              alt="Icone simbolizando que não existe acompanhamento a serem exibidas"
            />
            <span>{translate('medicalRecord.notAccompaniment')}</span>
          </N.NoData>
        )}

        {records.medicalRecordsFollowUp &&
          Object.keys(records.medicalRecordsFollowUp).map((item) => (
            <TimelineItem key={item}>
              <TimelineOppositeContent className={classes.left}>
                <CardDate
                  day={moment.utc(item).format('DD')}
                  month={moment.utc(item).format('MMM')}
                  year={moment.utc(item).format('YYYY')}
                />
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent className={classes.right}>
                {records.medicalRecordsFollowUp[item].map((i: any) => (
                  <Accordion key={i.id}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id={i.id}
                    >
                      <Typography
                        className={classes.heading}
                        style={{
                          marginTop: 0,
                          display: 'flex',
                          flex: 1,
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        {`${translate('medicalRecord.by')}  ${i.nursing?.name}`}

                        <Typography
                          style={{
                            marginTop: 0,
                          }}
                        >
                          {`(${i.specialty_name})`}
                        </Typography>
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{ display: 'block', padding: 0 }}>
                      <TimeLineFollowUp history={i} />
                    </AccordionDetails>
                  </Accordion>
                ))}
              </TimelineContent>
            </TimelineItem>
          ))}
      </Timeline>
    </>
  )
}

export default FollowUp
