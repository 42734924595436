import React from 'react'
import { Chat } from 'components'

import * as S from './styles'
import { IComponentProps } from './interfaces'

const ChatPatient: React.FunctionComponent<IComponentProps> = ({
  scheduleUUID,
  scheduleData,
}) => {
  return (
    <S.SectionContent>
      <Chat
        appointmentId={scheduleUUID}
        doctorId={scheduleData?.doctor?.id}
        doctorName={scheduleData?.doctor?.name}
      />
    </S.SectionContent>
  )
}

export default ChatPatient
