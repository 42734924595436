import React from 'react'
import moment from 'moment'
import { translate } from 'locales'

// Styles
import { Title, Button } from 'components'
import * as S from './styles'

// Interfaces
import { IComponentProps } from './interfaces'

const ExamsAndProcedures: React.FunctionComponent<IComponentProps> = ({
  doctorName,
  exams,
}) => {
  return (
    <S.SectionContent>
      <span>{moment(new Date()).format('dddd, DD/MM/YYYY')}</span>
      <p>{`${translate('medicalRecord.by')} ${doctorName}`}</p>

      <div className="subsection">
        <Title text={translate('medicalRecord.exams')} />
        <div className="cards-prescriptions">
          {exams &&
            exams
              .filter((item: any) => !!item.examPDF)
              ?.map((exam: any) => (
                <S.CardPrescriptionExams key={exam.uuid}>
                  <h1>{exam.codigoValidacao}</h1>
                  <span>
                    {`${translate('medicalRecord.issuedOn')} ${moment(
                      exam.createdAt
                    ).format('DD/MM/YYYY')}`}
                  </span>
                  <Button
                    type="button"
                    color="primary-light"
                    onClick={() => window.open(exam.examPDF, '_blank')}
                    styles={{ width: '100%' }}
                  >
                    {translate('medicalRecord.viesExams')}
                  </Button>
                </S.CardPrescriptionExams>
              ))}
        </div>
      </div>

      {exams && exams.filter((exam: any) => !!exam.attestedPDF).length ? (
        <div className="subsection">
          <Title text="Atestados" />
          <div className="cards-prescriptions">
            {exams &&
              exams
                .filter((item: any) => !!item.attestedPDF)
                ?.map((attested: any) => (
                  <S.CardPrescriptionExams key={attested.uuid}>
                    <h1>{attested.codigoValidacao}</h1>
                    <span>
                      {`${translate('medicalRecord.issuedOn')} ${moment(
                        attested.createdAt
                      ).format('DD/MM/YYYY')}`}
                    </span>
                    <Button
                      type="button"
                      color="primary-light"
                      onClick={() =>
                        window.open(attested.attestedPDF, '_blank')
                      }
                      styles={{ width: '100%' }}
                    >
                      {translate('medicalRecord.viewAttest')}
                    </Button>
                  </S.CardPrescriptionExams>
                ))}
          </div>
        </div>
      ) : null}
    </S.SectionContent>
  )
}

export default ExamsAndProcedures
